import React, { useEffect, useState } from 'react'
import S from './MyAccount.module.scss';
import { UserInfoService } from '../../../../service/UserInfoService';
import { AuthorizationTokenAtom, MyInfoAtom } from '../../../../recoil/Authorization.recoil';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MetaAdAccountType, TikTokAdAccountType, GoogleAdAccountType, UserTypeEnum } from '../../../../types/LoginTypes'
import { IoChevronDownOutline, IoChevronUpOutline, IoHelpCircleOutline } from 'react-icons/io5';
import { SiMeta, SiTiktok, SiGoogle } from 'react-icons/si';
import UserManageModal from './UserManageModal';
import { deleteAccountUser, updateAccountUserType } from '../../../../repository/AccountRepository';
import axios from 'axios';
import DeleteConfirmModal from './DeleteConfirmModal';
import EditUserTypeModal from './EditUserTypeModal';

interface UserForDelete {
  id: number;
  email: string;
}

interface UserForEdit {
  id: number;
  email: string;
  type: string;
}

const MyAccount = () => {
  UserInfoService();
  const token = useRecoilValue(AuthorizationTokenAtom);
  const myInfo = useRecoilValue(MyInfoAtom);
  const setMyInfo = useSetRecoilState(MyInfoAtom);
  const [metaAdAccounts, setMetaAdAccounts] = useState<MetaAdAccountType[]>([]);
  const [tikTokAdAccounts, setTikTokAdAccounts] = useState<TikTokAdAccountType[]>([]);
  const [googleAdAccounts, setGoogleAdAccounts] = useState<GoogleAdAccountType[]>([]);
  const [isMetaAdAccountsVisible, setMetaAdAccountsVisible] = useState(true);
  const [isTikTokAdAccountsVisible, setTikTokAdAccountsVisible] = useState(true);
  const [isGoogleAdAccountsVisible, setGoogleAdAccountsVisible] = useState(true);
  const [isUsersVisible, setUsersVisible] = useState(true);
  const [searchEmail, setSearchEmail] = useState('');
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState<{
    message: string;
    type: 'success' | 'error' | null;
  }>({ message: '', type: null });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<UserForDelete | null>(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<UserForEdit | null>(null);

  useEffect(() => {
    if(myInfo?.adAccount?.metaAdAccounts) setMetaAdAccounts(myInfo.adAccount.metaAdAccounts);
    if(myInfo?.adAccount?.tikTokAdAccounts) setTikTokAdAccounts(myInfo.adAccount.tikTokAdAccounts);
    if(myInfo?.adAccount?.googleAdAccounts) setGoogleAdAccounts(myInfo.adAccount.googleAdAccounts);
  }, [myInfo, token]);

  // 이메일 필터링 함수
  const filteredUsers = myInfo?.adAccount?.accountUsers?.filter(user => 
    user.email.toLowerCase().includes(searchEmail.toLowerCase())
  );

  // 사용자 관리 권한 체크 함수
  const hasUserManagePermission = myInfo?.type === 'ADMIN' || myInfo?.type === 'NORMAL_MASTER';
  
  // 유저 타입 옵션 (ADMIN 제외)
  const userTypeOptions = [
    { value: 'NORMAL_MASTER', label: '마스터' },
    { value: 'NORMAL_WRITEABLE', label: '읽기/쓰기 가능' },
    { value: 'NORMAL_READONLY', label: '읽기 전용' }
  ];

  // 유형별 설명을 한글로 변경
  const userTypeDescriptions = {
    'NORMAL_MASTER': '마스터 권한: 모든 기능 사용 및 사용자 관리 가능',
    'NORMAL_WRITEABLE': '일반 권한: 읽기/쓰기 기능 사용 가능',
    'NORMAL_READONLY': '읽기 전용: 읽기 전용 기능만 사용 가능'
  } as const;

  const handleUserAddSuccess = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/api/v1/me`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setMyInfo(data);
      }
    } catch (error) {
      console.error('Failed to fetch user info:', error);
    }
  };

  const handleUserEdit = (user: UserForEdit) => {
    setUserToEdit(user);
    setEditModalOpen(true);
  };

  const handleDeleteClick = (user: UserForDelete) => {
    setUserToDelete(user);
    setDeleteModalOpen(true);
  };

  const handleUserDelete = async (userId: number) => {
    try {
      await deleteAccountUser(userId, token);
      
      setDeleteStatus({
        message: '사용자가 성공적으로 삭제되었습니다.',
        type: 'success'
      });

      await handleUserAddSuccess();
      setDeleteModalOpen(false);
      setUserToDelete(null);

      setTimeout(() => {
        setDeleteStatus({ message: '', type: null });
      }, 3000);

    } catch (err) {
      if (axios.isAxiosError(err)) {
        const status = err.response?.status;
        let errorMessage = '사용자 삭제 중 오류가 발생했습니다.';
        
        switch (status) {
          case 401:
            errorMessage = '인증이 만료되었습니다. 다시 로그인해주세요.';
            break;
          case 403:
            errorMessage = '사용자 삭제 권한이 없습니다.';
            break;
          case 404:
            errorMessage = '존재하지 않는 사용자입니다.';
            break;
        }
        
        setDeleteStatus({
          message: errorMessage,
          type: 'error'
        });
      } else {
        setDeleteStatus({
          message: '알 수 없는 오류가 발생했습니다.',
          type: 'error'
        });
      }

      setTimeout(() => {
        setDeleteStatus({ message: '', type: null });
      }, 3000);
    }
  };

  const handleUserTypeUpdate = async (newType: string) => {
    if (!userToEdit) return;

    try {
      await updateAccountUserType(userToEdit.id, newType, token);
      
      setDeleteStatus({
        message: '사용자 권한이 성공적으로 수정되었습니다.',
        type: 'success'
      });

      await handleUserAddSuccess();
      setEditModalOpen(false);
      setUserToEdit(null);

      setTimeout(() => {
        setDeleteStatus({ message: '', type: null });
      }, 3000);

    } catch (err) {
      if (axios.isAxiosError(err)) {
        const status = err.response?.status;
        let errorMessage = '사용자 권한 수정 중 오류가 발생했습니다.';
        
        switch (status) {
          case 401:
            errorMessage = '인증이 만료되었습니다. 다시 로그인해주세요.';
            break;
          case 403:
            errorMessage = '사용자 권한 수정 권한이 없습니다.';
            break;
          case 404:
            errorMessage = '존재하지 않는 사용자입니다.';
            break;
        }
        
        setDeleteStatus({
          message: errorMessage,
          type: 'error'
        });
      }

      setTimeout(() => {
        setDeleteStatus({ message: '', type: null });
      }, 3000);
    }
  };

  if (!myInfo.id) return <div className={S.loading}>Loading...</div>;

  return (
    <div className={S.container}>
      {deleteStatus.message && (
        <div className={`${S.statusMessage} ${S[deleteStatus.type || '']}`}>
          {deleteStatus.message}
        </div>
      )}
      
      <div className={S.section}>
        <h2 className={S.sectionTitle}>계정 정보</h2>
        <div className={S.card}>
          <div className={S.field}>
            <label>이메일</label>
            <input type="text" value={myInfo.email} readOnly />
          </div>
          <div className={S.field}>
            <label>계정 유형</label>
            <input type="text" value={myInfo.type} readOnly />
          </div>
        </div>
      </div>

      <div className={S.section}>
        <h2 className={S.sectionTitle}>광고 계정 정보</h2>
        <div className={S.card}>
          <div className={S.field}>
            <label>계정명</label>
            <input type="text" value={myInfo.adAccount.name} readOnly />
          </div>
          <div className={S.field}>
            <label>설명</label>
            <input type="text" value={myInfo.adAccount.description} readOnly />
          </div>
          <div className={S.field}>
            <label>등급</label>
            <input type="text" value={myInfo.adAccount.tier} readOnly />
          </div>

          <div className={S.platformSection}>
            <div className={S.platformHeader} onClick={() => setMetaAdAccountsVisible(!isMetaAdAccountsVisible)}>
              <div className={S.platformTitle}>
                <SiMeta className={S.platformIcon} />
                Meta 광고 계정
              </div>
              {isMetaAdAccountsVisible ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
            </div>
            {isMetaAdAccountsVisible && (
              <div className={S.accountsList}>
                {metaAdAccounts.map((account) => (
                  <div key={account.metaAccountId} className={S.accountCard}>
                    <div className={S.field}>
                      <label>계정명</label>
                      <input type="text" value={account.name} readOnly />
                    </div>
                    <div className={S.field}>
                      <label>설명</label>
                      <input type="text" value={account.description} readOnly />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={S.platformSection}>
            <div className={S.platformHeader} onClick={() => setTikTokAdAccountsVisible(!isTikTokAdAccountsVisible)}>
              <div className={S.platformTitle}>
                <SiTiktok className={S.platformIcon} />
                TikTok 광고 계정
              </div>
              {isTikTokAdAccountsVisible ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
            </div>
            {isTikTokAdAccountsVisible && (
              <div className={S.accountsList}>
                {tikTokAdAccounts.map((account) => (
                  <div key={account.advertiserId} className={S.accountCard}>
                    <div className={S.field}>
                      <label>계정명</label>
                      <input type="text" value={account.name} readOnly />
                    </div>
                    <div className={S.field}>
                      <label>설명</label>
                      <input type="text" value={account.description} readOnly />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={S.platformSection}>
            <div className={S.platformHeader} onClick={() => setGoogleAdAccountsVisible(!isGoogleAdAccountsVisible)}>
              <div className={S.platformTitle}>
                <SiGoogle className={S.platformIcon} />
                Google 광고 계정
              </div>
              {isGoogleAdAccountsVisible ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
            </div>
            {isGoogleAdAccountsVisible && (
              <div className={S.accountsList}>
                {googleAdAccounts.map((account) => (
                  <div key={account.customerId} className={S.accountCard}>
                    <div className={S.field}>
                      <label>계정명</label>
                      <input type="text" value={account.name} readOnly />
                    </div>
                    <div className={S.field}>
                      <label>설명</label>
                      <input type="text" value={account.description} readOnly />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={S.section}>
        <div className={S.sectionHeader}>
          <h2 className={S.sectionTitle}>계정 사용자</h2>
          {(myInfo.type === 'ADMIN' || myInfo.type === 'NORMAL_MASTER') && (
            <button 
              className={S.addButton}
              onClick={() => setAddModalOpen(true)}
            >
              사용자 추가
            </button>
          )}
        </div>
        {isUsersVisible && (
          <div className={S.userTableCard}>
            <div className={S.searchWrapper}>
              <input
                type="text"
                placeholder="이메일로 검색"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
                className={S.searchInput}
              />
            </div>
            <table className={S.userTable}>
              <thead>
                <tr>
                  <th>이메일</th>
                  <th>상태</th>
                  <th>유형</th>
                  {(myInfo.type === 'ADMIN' || myInfo.type === 'NORMAL_MASTER') && <th>관리</th>}
                </tr>
              </thead>
              <tbody>
                {filteredUsers?.map((user) => (
                  <tr key={user.id}>
                    <td>{user.email}</td>
                    <td>{user.status}</td>
                    <td>{UserTypeEnum[user.type]}</td>
                    {(myInfo.type === 'ADMIN' || myInfo.type === 'NORMAL_MASTER') && (
                      <td className={S.actionCell}>
                        <div className={S.actionButtons}>
                          <button 
                            className={S.editButton}
                            onClick={() => handleUserEdit({
                              id: user.id,
                              email: user.email,
                              type: user.type
                            })}
                          >
                            수정
                          </button>
                          <button 
                            className={S.deleteButton}
                            onClick={() => handleDeleteClick({
                              id: user.id,
                              email: user.email
                            })}
                          >
                            삭제
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <UserManageModal
        isOpen={isAddModalOpen}
        onClose={() => setAddModalOpen(false)}
        onSuccess={handleUserAddSuccess}
      />

      <DeleteConfirmModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setUserToDelete(null);
        }}
        onConfirm={() => userToDelete && handleUserDelete(userToDelete.id)}
        email={userToDelete?.email || ''}
      />

      <EditUserTypeModal
        isOpen={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setUserToEdit(null);
        }}
        onConfirm={handleUserTypeUpdate}
        currentType={userToEdit?.type || ''}
        email={userToEdit?.email || ''}
      />
    </div>
  )
};

export default MyAccount;