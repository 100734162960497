import { AD_STATUS } from './MetaDataTypes'

export interface VerifyTokenType {
  success: boolean;
  description: string;
}

export interface VerifyLoginResponse {
  authenticated: boolean;
  accessToken: string;
}

export type UserStatusType = 'NORMAL' | 'INACTIVE' | 'DELETED';

export enum UserTypeEnum {
  NORMAL_MASTER = '마스터',
  NORMAL_WRITEABLE = '읽기/쓰기 가능',
  NORMAL_READONLY = '읽기 전용'
}

export interface AccountUserType {
  id: number;
  email: string;
  status: UserStatusType;
  type: keyof typeof UserTypeEnum;
}

export interface MyInfoType {
  id: number;
  email: string;
  type: string;
  status: UserStatusType;
  adAccount: {
    id: number;
    name: string;
    description: string;
    tier: string;
    metaAdAccounts: MetaAdAccountType[];
    tikTokAdAccounts: TikTokAdAccountType[];
    googleAdAccounts: GoogleAdAccountType[];
    accountUsers: AccountUserType[];
  };
}

export interface AdAccountType {
  id: number;
  name: string;
  tier: string;
  description: string;
  metaAdAccounts: MetaAdAccountType[];
  tikTokAdAccounts: TikTokAdAccountType[];
}

export interface MetaAdAccountType {
  metaAccountId: string;
  metaAccountName: string;
  latestSyncHistories: LatestSyncHistoryType[]
  name: string;
  adAccountId: number;
  currency: string;
  timezone: string;
  description: string;
  campaigns: MetaCampaignType[];
}

export interface TikTokAdAccountType {
  advertiserId: string;
  advertiserName: string;
  latestSyncHistories: LatestSyncHistoryType[]
  name: string;
  adAccountId: number;
  currency: string;
  timezone: string;
  description: string;
  campaigns: TikTokCampaignType[];
}

export interface LatestSyncHistoryType {
  metaAccountId: string,
  status: string,
  updatedAt: string,
  userId: number,
  createdAt: string
}

export interface MetaCampaignType {
  campaignId: string;
  campaignName: string;
  status: AD_STATUS;
  startTime: string;
  updatedTime: string;
  createdTime: string;
}

export interface TikTokCampaignType {
  campaignId: string;
  campaignName: string;
  status: AD_STATUS;
  startTime: string;
  updatedTime: string;
  createdTime: string;
}

interface SyncHistory {
  customerId: string;
  userId: number;
  status: 'SUCCESS' | 'FAILED' | 'IN_PROGRESS';
  createdAt: string;
  updatedAt: string;
}

export interface GoogleAdAccountType {
  customerId: string;
  name: string;
  currency: string;
  timezone: string;
  description: string;
  campaigns: {
    id: number;
    campaignId: string;
    name: string;
    status: string;
    startDate: string;
    endDate: string;
    budget: number;
    budgetStatus: string;
  }[];
  latestSyncHistories: SyncHistory[];
}