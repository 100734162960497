import React, { useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import S from './Sidebar.module.scss';
import { MetaAccountsAtom, TikTokAccountsAtom, GoogleAdAccountsAtom } from '../../../recoil/Data.recoil'
import { useRecoilValue } from 'recoil'
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { HiOutlineDatabase } from 'react-icons/hi';
import { SiMeta, SiTiktok, SiGoogle } from 'react-icons/si';

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [hoveredMenu, setHoveredMenu] = useState<string | null>(null);
  const metaAccounts = useRecoilValue(MetaAccountsAtom);
  const tikTokAccounts = useRecoilValue(TikTokAccountsAtom);
  const googleAdAccounts = useRecoilValue(GoogleAdAccountsAtom);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleMouseEnter = (menu: string) => {
    setHoveredMenu(menu);
  };

  const handleMouseLeave = () => {
    setHoveredMenu(null);
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const isActiveMenu = (menu: string) => location.pathname.includes(menu);

  const renderMenu = () => {
    return (
      <>
        <NavLink to="/data/raw" className={({ isActive }) => isActive ? S.active : S.navItem}>
          {!isCollapsed && (
            <>
              <HiOutlineDatabase style={{ marginRight: '8px' }} />
              광고데이터
            </>
          )}
          {isCollapsed && <HiOutlineDatabase />}
        </NavLink>
        {/* META 메뉴 */}
        {metaAccounts.length > 0 && (
          <div
          className={S.navItem}
          onMouseEnter={() => handleMouseEnter('meta')}
          onMouseLeave={handleMouseLeave}
        >
          <NavLink
            to="/data/meta/sets"
            className={({ isActive }) => (isActiveMenu('meta') ? S.active : S.navItem)}
          >
            {!isCollapsed && (
              <>
                <SiMeta style={{ marginRight: '8px' }} />
                META
              </>
            )}
            {isCollapsed && <SiMeta />}
          </NavLink>
          {!isCollapsed && (
            <div className={`${S.subMenu} ${
              isActiveMenu('meta') || hoveredMenu === 'meta' ? S.showSubMenu : ''
            }`}>
              <NavLink to="/data/meta/sets" className={({ isActive }) => (isActive ? S.subActive : S.subNavItem)}>
                SETS
              </NavLink>
              <NavLink to="/data/meta/ad" className={({ isActive }) => (isActive ? S.subActive : S.subNavItem)}>
                AD
              </NavLink>
            </div>
          )}
        </div>
        )}
        {tikTokAccounts.length > 0 && (
          <div
            className={S.navItem}
            onMouseEnter={() => handleMouseEnter('tiktok')}
            onMouseLeave={handleMouseLeave}
          >
            <NavLink
              to="/data/tiktok/ad-group"
              className={({ isActive }) =>
                isActiveMenu('tiktok') ? S.active : S.navItem
              }
            >
              {!isCollapsed && (
                <>
                  <SiTiktok style={{ marginRight: '8px' }} />
                  TIKTOK
                </>
              )}
              {isCollapsed && <SiTiktok />}
            </NavLink>
            {!isCollapsed && (
              <div
                className={`${S.subMenu} ${
                  isActiveMenu('tiktok') || hoveredMenu === 'tiktok'
                    ? S.showSubMenu
                    : ''
                }`}
              >
                <NavLink
                  to="/data/tiktok/ad-group"
                  className={({ isActive }) =>
                    isActive ? S.subActive : S.subNavItem
                  }
                >
                  AD GROUP
                </NavLink>
                <NavLink
                  to="/data/tiktok/ad"
                  className={({ isActive }) =>
                    isActive ? S.subActive : S.subNavItem
                  }
                >
                  AD
                </NavLink>
              </div>
            )}
          </div>
        )}

        {/* Google 메뉴 */}
        {googleAdAccounts.length > 0 && (
          <div
            className={S.navItem}
            onMouseEnter={() => handleMouseEnter('google')}
            onMouseLeave={handleMouseLeave}
          >
            <NavLink
              to="/data/google/campaign"
              className={({ isActive }) =>
                isActiveMenu('google') ? S.active : S.navItem
              }
            >
              {!isCollapsed && (
                <>
                  <SiGoogle style={{ marginRight: '8px' }} />
                  GOOGLE
                </>
              )}
              {isCollapsed && <SiGoogle />}
            </NavLink>
            {!isCollapsed && (
              <div
                className={`${S.subMenu} ${
                  isActiveMenu('google') || hoveredMenu === 'google'
                    ? S.showSubMenu
                    : ''
                }`}
              >
                <NavLink
                  to="/data/google/campaign"
                  className={({ isActive }) =>
                    isActive ? S.subActive : S.subNavItem
                  }
                >
                  CAMPAIGN
                </NavLink>
              </div>
            )}
          </div>
        )}
      </>
    )
  };

  return (
    <aside className={`${S.sidebar} ${isCollapsed ? S.collapsed : ''}`}>
      <button className={S.toggleButton} onClick={toggleSidebar}>
        {isCollapsed ? <IoChevronForwardOutline /> : <IoChevronBackOutline />}
      </button>
      <nav className={S.nav}>
        {renderMenu()}
      </nav>
    </aside>
  );
};

export default Sidebar;