import React, { useEffect } from 'react'
import './App.css'
import { BrowserRouter } from 'react-router-dom'
import Router from './components/router'
import { RecoilRoot } from 'recoil'
import { UserInfoService } from './service/UserInfoService'

function AppContent() {
  UserInfoService();  // UserInfoService 호출
  return <Router />;
}

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <RecoilRoot>
        <AppContent />
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
