import Header from '../common/Header'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import ErrorBoundary from '../common/ErrorBoundary'
import React from 'react';
import S from './Router.module.scss';
import Login from '../awake/Login'
import Auth from '../awake/Auth'
import Sidebar from '../common/Sidebar'
import RawData from '../awake/Data/RawData'
import MetaSettings from '../awake/Settings'
import MyAccount from '../awake/My/Account/MyAccount'
import MetaSetsData from '../awake/Data/Meta/MetaSets'
import MetaAdsData from '../awake/Data/Meta/MetaAds'
import Main from '../awake/Main'
import Footer from '../common/Footer'
import Terms from '../common/Footer/Terms'
import Privacy from '../common/Footer/Privacy'
import TikTokAdGroupData from '../awake/Data/Tiktok/TikTokAdGroup'
import TikTokAdsData from '../awake/Data/Tiktok/TikTokAds'
import Campaign from '../awake/Data/Google/Campaign'

const Router = () => {
  const location = useLocation()
  return (
    <div className={S.container}>
      <div className={S.contentWrapper}>
        <ErrorBoundary>
          <React.Suspense fallback={<></>}>
            {location.pathname !== '/login' && <HeaderRouter />}
          </React.Suspense>
        </ErrorBoundary>
        <ErrorBoundary>
          <React.Suspense fallback={<></>}>
            <BodyWrapper />
          </React.Suspense>
        </ErrorBoundary>
      </div>
      <ErrorBoundary>
        <React.Suspense fallback={<></>}>
          <FooterRouter />
        </React.Suspense>
      </ErrorBoundary>
    </div>
  )
}

const HeaderRouter = () => (
  <Routes>
    <Route path={'/*'} element={<Header />} />
  </Routes>
)

const BodyWrapper = () => {
  const location = useLocation()
  return (
    <div className={S.bodyWrapper}>
      {location.pathname !== '/login' && <Sidebar />}
      <BodyRouter />
    </div>
  )
}

const BodyRouter = () => (
  <Routes>
    <Route path="/" element={<Main />} />
    <Route path="/data/raw" element={<RawData />} />

    <Route path="/data" element={<MetaSetsData />} />
    <Route path="/data/meta/sets" element={<MetaSetsData />} />
    <Route path="/data/meta/ad" element={<MetaAdsData />} />

    <Route path="/data/tiktok/ad-group" element={<TikTokAdGroupData />} />
    <Route path="/data/tiktok/ad" element={<TikTokAdsData />} />

    <Route path="/data/google/campaign" element={<Campaign />} />

    {/*<Route path="/ad-settings/meta" element={<MetaSettings />} />*/}

    <Route path="/my-info" element={<MyAccount />} />
    <Route path="/my-info/account" element={<MyAccount />} />
    <Route path={'/login'} element={<Login />} />
    <Route path={'/auth'} element={<Auth />} />

    {/* 존재하지 않는 경로일 경우 login으로 리다이렉트 */}
    <Route path="*" element={<Navigate to="/login" replace />} />
  </Routes>
)

const FooterRouter = () => (
  <Routes>
    <Route path={'/*'} element={<Footer />} />
  </Routes>
)

export default Router;