import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { UserTypeEnum } from '../../../../types/LoginTypes';
import { createAccountUser } from '../../../../repository/AccountRepository';
import { useRecoilValue } from 'recoil';
import { AuthorizationTokenAtom } from '../../../../recoil/Authorization.recoil';
import S from './UserManageModal.module.scss';

interface UserManageModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const UserManageModal = ({ isOpen, onClose, onSuccess }: UserManageModalProps) => {
  const token = useRecoilValue(AuthorizationTokenAtom);
  const [email, setEmail] = useState('');
  const [type, setType] = useState<keyof typeof UserTypeEnum>('NORMAL_READONLY');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  // 모달이 열릴 때마다 상태 초기화
  useEffect(() => {
    if (isOpen) {
      setEmail('');
      setType('NORMAL_READONLY');
      setError(null);
      setSuccessMessage(null);
      setIsLoading(false);
    }
  }, [isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);
    setIsLoading(true);

    try {
      await createAccountUser({
        email,
        type
      }, token);
      
      setSuccessMessage('사용자가 성공적으로 추가되었습니다.');
      onSuccess();
      
      setTimeout(() => {
        onClose();
        setEmail('');
        setType('NORMAL_READONLY');
        setSuccessMessage(null);
      }, 2000);
      
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError<{ message: string }>;
        const status = error.response?.status;
        
        let errorMessage = '사용자 추가 중 오류가 발생했습니다.';
        
        switch (status) {
          case 401:
            errorMessage = '인증이 만료되었습니다. 다시 로그인해주세요.';
            break;
          case 403:
            errorMessage = '사용자 추가 권한이 없습니다.';
            break;
          case 400:
            errorMessage = error.response?.data?.message || '잘못된 요청입니다. 입력값을 확인해주세요.';
            break;
          case 409:
            errorMessage = '이미 존재하는 사용자입니다.';
            break;
        }
        
        setError(errorMessage);
      } else {
        setError('알 수 없는 오류가 발생했습니다.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={S.overlay}>
      <div className={S.modal}>
        <h2>사용자 추가</h2>
        <form onSubmit={handleSubmit}>
          <div className={S.field}>
            <label>이메일</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="이메일 주소를 입력하세요"
              required
            />
          </div>
          <div className={S.field}>
            <label>유형</label>
            <select
              value={type}
              onChange={(e) => setType(e.target.value as keyof typeof UserTypeEnum)}
              required
            >
              <option value="NORMAL_READONLY">읽기 전용</option>
              <option value="NORMAL_WRITEABLE">읽기/쓰기 가능</option>
              <option value="NORMAL_MASTER">마스터</option>
            </select>
          </div>
          {error && (
            <div className={S.error}>
              {error}
            </div>
          )}
          {successMessage && (
            <div className={S.success}>
              {successMessage}
            </div>
          )}
          <div className={S.actions}>
            <button 
              type="button" 
              onClick={onClose} 
              className={S.cancelButton}
              disabled={isLoading}
            >
              취소
            </button>
            <button 
              type="submit" 
              className={S.submitButton}
              disabled={isLoading}
            >
              {isLoading ? '처리중...' : '추가'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserManageModal; 