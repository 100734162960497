import React, { useEffect, useState, Suspense } from 'react'
import { FaSyncAlt } from 'react-icons/fa'
import Switch from 'react-switch'
import 'react-datepicker/dist/react-datepicker.css'
import S from './TikTokAdGroupData.module.scss'
import { UserInfoService } from '../../../../../service/UserInfoService'
import {
  AuthorizationTokenAtom,
  MyInfoAtom,
} from '../../../../../recoil/Authorization.recoil'
import { useRecoilState, useRecoilValue } from 'recoil'
import _ from 'lodash'

import dayjs from 'dayjs'
import { TikTokAccountsAtom } from '../../../../../recoil/Data.recoil'
import AdCreativePopup from '../AdCreativePopup'
import CommentPopup from '../CommentPopup'
import {
  AD_STATUS, SortingType,
  TikTokAdGroupType,
  TikTokAdType,
  TikTokDataFilterType,
  TikTokStatusEditType,
} from '../../../../../types/TikTokDataTypes'
import {
  changeAdGroupDailyBudget,
  changeAdGroupStatus,
  changeAdStatus,
  fetchTikTokAccounts,
  fetchTikTokAdGroupData,
  syncDataManually, tiktokBulkChangeAdGroupDailyBudget, tiktokBulkChangeAdGroupStatus,
} from '../../../../../repository/TikTokDataRepository'
import TikTokDataSearch from '../TikTokDataSearch'
import AdGroupCreativePopup from '../AdGroupCreativePopup'

// 직접 ErrorBoundary 클래스 구현
interface Props {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  onError?: () => void;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Popup Error:', error, errorInfo);
    if (this.props.onError) {
      this.props.onError();
    }
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}

// 안전한 팝업 래퍼 컴포넌트
const SafeAdCreativePopup = ({ adId, from, to, onClose }: any) => {
  try {
    return (
      <ErrorBoundary 
        onError={() => {
          alert('광고 데이터를 불러올 수 없습니다.\n관리자에게 문의해주세요.');
          onClose();
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <AdCreativePopup
            adId={adId}
            from={from}
            to={to}
            onClose={onClose}
          />
        </Suspense>
      </ErrorBoundary>
    );
  } catch (error) {
    console.error('Error in SafeAdCreativePopup:', error);
    onClose();
    return null;
  }
};

const SafeAdGroupCreativePopup = ({ adGroupId, from, to, onClose }: any) => {
  try {
    return (
      <ErrorBoundary 
        onError={() => {
          alert('광고 그룹 데이터를 불러올 수 없습니다.\n관리자에게 문의해주세요.');
          onClose();
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <AdGroupCreativePopup
            adGroupId={adGroupId}
            from={from}
            to={to}
            onClose={onClose}
          />
        </Suspense>
      </ErrorBoundary>
    );
  } catch (error) {
    console.error('Error in SafeAdGroupCreativePopup:', error);
    onClose();
    return null;
  }
};

const TikTokAdGroupData = () => {
  UserInfoService()

  const token = useRecoilValue(AuthorizationTokenAtom)
  const myInfo = useRecoilValue(MyInfoAtom)
  const [tikTokAccounts, setTikTokAccounts] = useRecoilState(TikTokAccountsAtom);
  const [data, setData] = useState<TikTokAdGroupType[]>([])
  const [expandedRowData, setExpandedRowData] = useState<{
    [key: number]: TikTokAdType[]
  }>({})
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null)
  const [filter, setFilters] = useState<TikTokDataFilterType>(
    {} as TikTokDataFilterType,
  )
  const [totalElements, setTotalElements] = useState(0)
  const [bulkBudget, setBulkBudget] = useState<number>(0) // 일괄 예산 조정을 위한 상태 추가
  const [bulkStatus, setBulkStatus] = useState<AD_STATUS>('') // 일괄 ON/OFF 조정을 위한 상태 추가
  const [sortConfig, setSortConfig] = useState({
    key: 'spend',
    direction: 'asc',
  } as SortingType)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [popupUsedAdId, setPopupUsedId] = useState<string>('')
  const [popupUsedAdGroupId, setPopupUsedAdGroupId] = useState<string>('')
  const [isAdPopupOpen, setIsAdPopupOpen] = useState(false)
  const [isAdGroupPopupOpen, setIsAdGroupPopupOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const [commentPopupData, setCommentPopupData] = useState<{adId: string, memo: string} | null>(null)
  const [popupData, setPopupData] = useState<any>(null);

  useEffect(() => {
    const ids = tikTokAccounts?.map((it) => it.advertiserId)
    if (!_.isEmpty(token) && ids?.length > 0) {
      const campaignIds = tikTokAccounts[0].campaigns?.map((it) => it.campaignId)
      if (campaignIds?.length > 0) {
        const initFilter = {
          advertiserId: ids[0],
          campaignId: campaignIds[0],
          adGroupId: '',
          adGroupName: '',
          startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
        } as TikTokDataFilterType
        setFilters((prev) => initFilter)
        fetchData(initFilter)
      }
    }
  }, [myInfo, tikTokAccounts])

  const handleRowMouseEnter = (rowIndex: number) => {
    setHoveredRowIndex(rowIndex);
  };

  const handleRowMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleSyncClick = () => {
    if(myInfo.adAccount.tier !== 'PREMIUM'){
      alert('프리미엄 티어 이상부터 사용가능 합니다.')
      return
    }

    setIsSyncing(true) // 동기화 중 상태 true
    syncDataManually(token, filter.startDate, filter.endDate)
      .then((res) => {
        if(res.success){
          alert('동기화 성공!')
          fetchTikTokAccounts(token)
            .then((res) => {
              setTikTokAccounts(res);
            })
            .catch((error) => console.error(error));
        }else{
          alert(`동기화 실패. \n${res.message}`)
        }
      })
      .catch((e) => {
        // 실패 시 처리
        console.error(e)
        alert('동기화 실패. \n관리자에게 문의하세요.')
      })
      .finally(() => {
        setIsSyncing(false) // 동기화 중 상태 false
      })
  }

  const getLatestSyncTime = () => {
    const successfulSyncs = tikTokAccounts[0]?.latestSyncHistories?.filter(
      (history) => history.status === 'SUCCESS',
    )
    if (successfulSyncs?.length > 0) {
      const latestSync = _.maxBy(successfulSyncs, (sync) =>
        dayjs(sync.updatedAt).valueOf(),
      )
      return latestSync?.updatedAt
        ? dayjs(latestSync.updatedAt).format('YYYY-MM-DD HH:mm:ss')
        : null
    }
    return null
  }

  const latestSyncTime = getLatestSyncTime()
  const isSyncSuccessful = latestSyncTime !== null

  const handleCheckboxChange = (setId: string) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(setId)
        ? prevSelected.filter((id) => id !== setId)
        : [...prevSelected, setId],
    )
  }

  // 전체 선택 체크박스 핸들러
  const handleSelectAll = () => {
    if (selectedRows.length === sortedData.length) {
      setSelectedRows([]) // 모두 선택된 상태에서 다시 클릭하면 모두 해제
    } else {
      setSelectedRows(sortedData.map((row) => row.adGroupId)) // 전체 선택
    }
  }

  const convertStatusOpposite = (status: string): AD_STATUS => {
    return status === 'ENABLE' ? 'DISABLE' : 'ENABLE'
  }

  const handleAdSwitchChange = (
    adId: string,
    adName: string,
    status: AD_STATUS,
  ) => {
    const action = status === 'ENABLE' ? 'off' : 'on';
    const message = `해당 광고 ${action} 하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');
    setIsLoading(true);

    if (memo != null) {
      changeAdStatus(token, adId, convertStatusOpposite(status), memo)
        .then((res) => {
          if (res.success) {
            alert(`[AD] ${adName} ${action === 'on' ? 'ON 성공' : 'OFF 성공'}`);
            handleSearch(filter);
          } else {
            alert(
              `[AD] ${adName} ${action === 'on' ? 'ON 실패' : 'OFF 실패'}\n 관리자 문의 부탁드립니다.`,
            );
          }
          setIsLoading(false);
        })
        .catch((e) => console.error(e));
    }
  };

  const updateAdGroupStatus = (
    adGroupId: string,
    adGroupName: string,
    status: AD_STATUS,
  ) => {
    const action = status === 'ENABLE' ? 'off' : 'on';
    const message = `해당 세트를 ${action} 하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');
    setIsLoading(true);

    if (memo != null) {
      changeAdGroupStatus(token, adGroupId, convertStatusOpposite(status), memo)
        .then((res) => {
          if (res.success) {
            alert(`[AD-GROUP] ${adGroupName} ${action === 'on' ? 'ON 성공' : 'OFF 성공'}`);
            setSelectedRows([]); // 체크박스 해제
            setBulkStatus(''); // Bulk 상태 초기화
            handleSearch(filter);
          } else {
            alert(`[AD-GROUP] ${adGroupName} ${action === 'on' ? 'ON 실패' : 'OFF 실패'}\n 관리자 문의 부탁드립니다.`);
          }
          setIsLoading(false);
        })
        .catch((e) => console.error(e));
    }
  };

  const updateBudget = (
    adGroupId: string,
    adGroupName: string,
    changedBudget: number,
  ) => {
    const message = `해당 세트의 예산을 ${changedBudget}로 설정하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');
    setIsLoading(true);

    if (memo != null) {
      changeAdGroupDailyBudget(token, adGroupId, changedBudget, memo)
        .then((res) => {
          if (res.success) {
            alert(`[AD-GROUP] ${adGroupName} 예산변경 성공`);
            setSelectedRows([]); // 체크박스 해제
            setBulkStatus(''); // Bulk 상태 초기화
            handleSearch(filter);
          } else {
            alert(`[AD-GROUP] ${adGroupName} 예산변경 실패\n 관리자 문의 부탁드립니다.`);
          }
          setIsLoading(false)
        })
        .catch((e) => console.error(e));
    }
  };


  const fetchData = (filter: TikTokDataFilterType) => {
    setIsLoading(true)
    const queryParams = [
      `advertiserId=${filter.advertiserId}`,
      filter.campaignId ? `campaignId=${filter.campaignId}` : '',
      filter.adGroupId ? `adGroupId=${filter.adGroupId}` : '',
      filter.adGroupName ? `adGroupName=${filter.adName}` : '',
      filter.adGroupStatus ? `adGroupStatus=${filter.adGroupStatus}` : '',
      filter.goeSpend !== undefined ? `goeSpend=${filter.goeSpend}` : '',
      filter.loeSpend !== undefined ? `loeSpend=${filter.loeSpend}` : '',
      `from=${filter.startDate}`,
      `to=${filter.endDate}`,
    ]
      .filter(Boolean) // 빈 문자열을 제거합니다.
      .join('&') // '&'로 쿼리 파라미터를 연결합니다.

    fetchTikTokAdGroupData(token, queryParams)
      .then((res) => {
        const updatedData = res.map((item) => ({
          ...item,
          changedDailyBudget: item.budget,
          ads: item.ads
        }))
        setData(updatedData)
        setTotalElements(updatedData.length)
      })
      .catch((e) => {
        console.error(e.message)
        alert('데이터 조회 실패!')
      })
      .finally(() => {
        setIsLoading(false) // 로딩 끝
      })
  }

  const handleBudgetChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number,
  ) => {
    const inputValue = e.target.value

    // 숫자만 입력되게 하고, 음수 및 0 미만의 값을 막는 로직
    if (!/^\d*\.?\d*$/.test(inputValue) || parseFloat(inputValue) < 0) {
      alert('숫자만 입력 가능하며, 음수는 허용되지 않습니다.')
      return
    }

    const newBudget = parseFloat(inputValue)

    if (isNaN(newBudget) || newBudget < 0) {
      alert('유효한 예산을 입력해주세요. 0 이상의 숫자만 가능합니다.')
      return
    }

    const selectedAdGroupId = sortedData[rowIndex].adGroupId

    setData((prevData) => {
      // prevData에서 동일한 setId를 가진 모든 항목을 업데이트
      const newData = prevData.map((item) => {
        if (item.adGroupId === selectedAdGroupId) {
          return {
            ...item,
            changedDailyBudget: newBudget,
          }
        }
        return item
      })

      return newData
    })
  }

  const handleRowClick = (expandedRowIndex: number | null, rowIndex: number) => {
    if (expandedRowIndex === rowIndex) {
      setExpandedRowIndex(null)
      setExpandedRowData((prev) => {
        const newState = { ...prev }
        delete newState[rowIndex]
        return newState
      })
    } else {
      const selectedRowData = sortedData[rowIndex]?.ads.sort((a, b) => {
        const key = sortConfig.key as keyof TikTokAdType
        if (a[key] < b[key]) {
          return sortConfig.direction === 'asc' ? -1 : 1
        }
        if (a[key] > b[key]) {
          return sortConfig.direction === 'asc' ? 1 : -1
        }
        return 0
      })

      setExpandedRowIndex(rowIndex)
      setExpandedRowData({ [rowIndex]: selectedRowData })
    }
  }

  const handleSearch = (filter: TikTokDataFilterType) => {
    setExpandedRowIndex(null);
    fetchData(filter)
  }

  const handleToggleClick = (e: React.MouseEvent) => {
    e.stopPropagation() // Row 클릭 이벤트로부터 이벤트 버블링을 막습니다.
  }

  const handleBulkStatusChange = (status: AD_STATUS) => {
    if (selectedRows.length === 0) {
      alert('선택된 항목이 없습니다.');
      return;
    }

    const action = status === 'ENABLE' ? '활성화' : '비활성화';
    const message = `선택된 세트를 ${action} 하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');

    if (memo != null) {
      setIsLoading(true);
      const requestPayload = data
        .filter((item) => selectedRows.includes(item.adGroupId))
        .map(
          (item) =>
            ({
              id: item.adGroupId,
              status: status,
              memo: memo, // memo를 각 요청에 포함
            } as TikTokStatusEditType),
        );

      tiktokBulkChangeAdGroupStatus(token, requestPayload)
        .then((responses) => {
          const failedIds = responses
            .filter((res) => !res.success)
            .map((res) => res.id);

          if (failedIds.length === 0) {
            alert('일괄 상태 변경 성공!');
          } else {
            alert(
              `일괄 상태 변경 실패! 실패한 ID: ${failedIds.join(', ')}\n 관리자에게 문의해주세요.`,
            );
          }

          setSelectedRows([]); // 체크박스 해제
          setBulkStatus(status); // Bulk 상태 업데이트
          handleSearch(filter);
          setIsLoading(false);
        })
        .catch((e) => console.error(e));
    }
  };


  const handleBulkBudgetChange = () => {
    if (selectedRows.length === 0) {
      alert('선택된 항목이 없습니다.')
      return
    }

    const message = `선택된 ad group의 예산을 ${bulkBudget}로 설정하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');

    if (window.confirm(message)) {
      setIsLoading(true);
      const requestPayload = data
        .filter((item) => selectedRows.includes(item.adGroupId))
        .map((item) => ({ id: item.adGroupId, dailyBudget: bulkBudget, memo: memo }))
      tiktokBulkChangeAdGroupDailyBudget(token, requestPayload)
        .then((responses) => {
          const failedIds = responses
            .filter((res) => !res.success)
            .map((res) => res.id)

          if (failedIds.length === 0) {
            alert('일괄 예산 변경 성공!')
          } else {
            alert(
              `일괄 예산 변경 실패! 실패한 ID: ${failedIds.join(', ')}\n 관리자에게 문의해주세요.`,
            )
          }

          setSelectedRows([]); // 체크박스 해제
          setBulkStatus(''); // Bulk 상태 초기화
          handleSearch(filter)
          setIsLoading(false);
        })
        .catch((e) => console.error(e))
    }
  }

  const handleBudgetEdit = (
    type: 'increase' | 'decrease',
    rowIndex: number,
  ) => {
    let percent = type === 'increase' ? 1.2 : 0.8

    // 선택된 setId를 가져옴
    const selectedAdGroupId = sortedData[rowIndex].adGroupId

    setData((prevData) => {
      // prevData에서 동일한 setId를 가진 모든 항목을 업데이트
      const newData = prevData.map((item) => {
        if (item.adGroupId === selectedAdGroupId) {
          // 예산을 곱하고, lodash의 round 함수를 사용해 정수로 반올림
          return {
            ...item,
            changedDailyBudget: _.round(item.changedDailyBudget * percent),
          }
        }
        return item
      })

      // 정렬 없이 그대로 반환
      return newData
    })
  }

  const sortedData = React.useMemo(() => {
    if (sortConfig.key) {
      const sorted = [...data].sort((a, b) => {
        const key = sortConfig.key as keyof TikTokAdGroupType
        if (a[key] < b[key]) {
          return sortConfig.direction === 'asc' ? -1 : 1
        }
        if (a[key] > b[key]) {
          return sortConfig.direction === 'asc' ? 1 : -1
        }
        return 0
      })

      return sorted
    }
    return data
  }, [data, sortConfig])

  const requestSort = (key: string) => {
    let direction = 'asc'
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    setSortConfig({ key, direction })
    //펼쳐져 있는것 닫기
    setExpandedRowIndex(null)
  }

  const handleOpenAdPopup = (adId: string) => {
    try {
      if (!adId) {
        throw new Error('Invalid ad id');
      }
      setPopupUsedId(adId);
      setIsAdPopupOpen(true);
    } catch (error) {
      alert('광고 데이터를 불러올 수 없습니다.\n관리자에게 문의해주세요.');
      console.error('Error opening ad popup:', error);
    }
  };

  const handleOpenAdGroupPopup = (adGroupId: string) => {
    try {
      if (!adGroupId) {
        throw new Error('Invalid ad group id');
      }
      setPopupUsedAdGroupId(adGroupId);
      setIsAdGroupPopupOpen(true);
    } catch (error) {
      alert('광고 그룹 데이터를 불러올 수 없습니다.\n관리자에게 문의해주세요.');
      console.error('Error opening ad group popup:', error);
    }
  };

  const handleClosePopup = () => {
    setIsAdPopupOpen(false);
    setIsAdGroupPopupOpen(false);
    setPopupUsedId('');
    setPopupUsedAdGroupId('');
  };

  const handleOpenCommentPopup = (adId: string, memo: string) => {
    setCommentPopupData({ adId, memo }); // 코멘트 팝업 열기
  };

  const handleCloseCommentPopup = (type: 'EDIT' | 'CANCEL') => {
    setCommentPopupData(null);
    if(type == 'EDIT'){
      fetchData(filter);
    }
  };

  const handlePopupError = () => {
    alert('데이터를 불러오는 중 오류가 발생했습니다.\n관리자에게 문의해주세요.');
    setIsAdPopupOpen(false);
    setIsAdGroupPopupOpen(false);
    setPopupUsedId('');
    setPopupUsedAdGroupId('');
  };

  useEffect(() => {
    const expandedRowNumberIndex = expandedRowIndex;
    if (expandedRowNumberIndex != null) {
      handleRowClick(null, expandedRowNumberIndex)
    }
  }, [sortedData]);

  if (tikTokAccounts.length === 0) {
    return <></>
  }

  return (
    <div className={S.container}>
      <div className={S.pageHeader}>
        <h1>TikTok 광고 관리</h1>
        <p className={S.pageDescription}>광고 그룹 및 광고 데이터를 관리합니다</p>
      </div>

      <div className={S.searchSection}>
        <TikTokDataSearch
          tikTokAccounts={tikTokAccounts || []}
          filters={filter}
          setFilters={setFilters}
          handleSearch={handleSearch}
          searchType={'AD-GROUP'}
        />
      </div>

      <div className={S.bulkControls}>
        <div className={S.controlGroup}>
          <label>광고 그룹 상태 일괄 변경</label>
          <div className={S.buttonGroup}>
            <button
              className={S.controlButton}
              onClick={() => handleBulkStatusChange('ENABLE')}
            >
              활성화
            </button>
            <button
              className={S.controlButton}
              onClick={() => handleBulkStatusChange('DISABLE')}
            >
              비활성화
            </button>
          </div>
        </div>

        <div className={S.controlGroup}>
          <label>예산 일괄 변경</label>
          <div className={S.budgetControls}>
            <input
              type="number"
              value={bulkBudget}
              onChange={(e) => setBulkBudget(Number(e.target.value))}
              placeholder="예산 입력"
              className={S.budgetInput}
            />
            <button
              className={S.controlButton}
              onClick={handleBulkBudgetChange}
              disabled={!bulkBudget}
            >
              적용
            </button>
          </div>
        </div>
      </div>

      <div className={S.dataSection}>
        <div className={S.dataInfo}>
          <div className={S.totalCount}>
            총 {totalElements.toLocaleString()}개의 데이터
          </div>
          <div className={S.syncInfo}>
            <div className={S.syncStatus}>
              <span className={isSyncSuccessful ? S.syncSuccess : S.syncError}>
                {isSyncSuccessful ? '동기화 완료' : '동기화 필요'}
              </span>
              {isSyncSuccessful && (
                <span className={S.syncTime}>최근 동기화: {latestSyncTime}</span>
              )}
            </div>
            <button
              className={`${S.syncButton} ${isSyncing ? S.syncing : ''}`}
              onClick={handleSyncClick}
              disabled={isSyncing}
            >
              {isSyncing ? <FaSyncAlt className={S.syncIcon} /> : '수동 동기화'}
            </button>
          </div>
        </div>

        {isLoading ? (
          <div className={S.loadingWrapper}>
            <div className={S.loader}></div>
            <span>데이터를 불러오는 중...</span>
          </div>
        ) : totalElements === 0 ? (
          <div className={S.noData}>데이터가 없습니다.</div>
        ) : (
          <div className={S.tableWrapper}>
            <table className={S.table}>
              <thead>
                <tr>
                  <th className={S.checkboxCell}>
                    <input
                      type="checkbox"
                      checked={selectedRows.length === sortedData.length}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th className={S.switchCell}>광고 그룹 ON/OFF</th>
                  <th>광고 예산 조정</th>
                  <th>Insights</th>
                  <th>캠페인 명</th>
                  <th>광고 그룹명</th>
                  <th>일일 예산</th>
                  <th onClick={() => requestSort('spend')}>
                    지출 {sortConfig.key === 'spend' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : null}
                  </th>
                  <th>ROAS</th>
                  <th>매출</th>
                </tr>
              </thead>
              <tbody>
                {sortedData?.map((row, rowIndex) => {
                  const isExpanded = rowIndex === expandedRowIndex
                  const isHovered = hoveredRowIndex === rowIndex;
                  return (
                    <React.Fragment key={`${row.campaignId}-${row.adGroupId}`}>
                      <tr
                        onClick={() => handleRowClick(expandedRowIndex, rowIndex)}
                        onMouseOver={() => handleRowMouseEnter(rowIndex)}
                        onMouseLeave={handleRowMouseLeave}
                        className={isExpanded ? S.expandedRow : ''}
                      >
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(row.adGroupId)}
                            onChange={() => handleCheckboxChange(row.adGroupId)}
                            onClick={handleToggleClick}
                          />
                        </td>
                        <td>
                          <div onClick={handleToggleClick}>
                            <Switch
                              checked={row.status === 'ENABLE'}
                              onChange={() =>
                                updateAdGroupStatus(row.adGroupId, row.adGroupName, row.status)
                              }
                              onColor="#2e7d32"
                              offColor="#e0e0e0"
                              onHandleColor="#ffffff"
                              offHandleColor="#ffffff"
                              handleDiameter={24}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={16}
                              width={36}
                              className="react-switch"
                            />
                          </div>
                        </td>
                        <td>
                          <div className={S.budgetControls} onClick={(e) => e.stopPropagation()}>
                            <input
                              type="number"
                              value={row.changedDailyBudget}
                              onChange={(e) => handleBudgetChange(e, rowIndex)}
                              className={S.budgetInput}
                            />
                            <div className={S.budgetButtons}>
                              <button
                                className={S.budgetBtn}
                                onClick={(e) => updateBudget(row.adGroupId, row.adGroupName, row.changedDailyBudget)}
                              >
                                예산 적용
                              </button>
                              <button
                                className={`${S.percentButton} ${S.increase}`}
                                onClick={() => handleBudgetEdit('increase', rowIndex)}
                              >
                                <span className={S.icon}>↑</span>
                                20%
                              </button>
                              <button
                                className={`${S.percentButton} ${S.decrease}`}
                                onClick={() => handleBudgetEdit('decrease', rowIndex)}
                              >
                                <span className={S.icon}>↓</span>
                                20%
                              </button>
                            </div>
                          </div>
                        </td>
                        <td>
                          <button
                            className={S.popupBtn}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenAdGroupPopup(row.adGroupId);
                            }}
                          >
                            자세히 보기
                          </button>
                        </td>
                        <td className={isHovered ? S.hoverable : ''}>{row.campaignName}</td>
                        <td className={isHovered ? S.hoverable : ''}>{row.adGroupName}</td>
                        <td className={isHovered ? S.hoverable : ''}>{row.budget.toLocaleString()}</td>
                        <td className={isHovered ? S.hoverable : ''}>{row.spend.toLocaleString()}</td>
                        <td className={isHovered ? S.hoverable : ''}>{row.roas}</td>
                        <td className={isHovered ? S.hoverable : ''}>{row.revenue.toLocaleString()}</td>
                      </tr>
                      {isExpanded && (
                        <tr className={S.expandedRowContent}>
                          <td colSpan={9} className={S.expandedCell}>
                            <table className={S.innerTable}>
                              <thead>
                                <tr>
                                  <th>광고 ON/OFF</th>
                                  <th>광고명</th>
                                  <th>지출</th>
                                  <th>매출</th>
                                  <th>ROAS</th>
                                  <th>소재 및 Insights</th>
                                  <th>노출</th>
                                  <th>CTR</th>
                                  <th>클릭 수</th>
                                  <th>CPC</th>
                                  <th>CPM</th>
                                  <th>구매당 매출</th>
                                  <th>전환율</th>
                                  <th>메모</th>
                                </tr>
                              </thead>
                              <tbody>
                                {expandedRowData[rowIndex]?.map((ad, adIndex) => (
                                  <tr key={`${ad.adId}-${ad.memo}`} className={S.expandedRowItem}>
                                    <td>
                                      <Switch
                                        checked={ad.status === 'ENABLE'}
                                        onChange={() =>
                                          handleAdSwitchChange(
                                            ad.adId,
                                            ad.name,
                                            ad.status,
                                          )
                                        }
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={20}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={15}
                                        width={30}
                                      />
                                    </td>
                                    <td>{ad.name}</td>
                                    <td>{ad.spend?.toLocaleString()}</td>
                                    <td>{ad.revenue?.toLocaleString()}</td>
                                    <td>{ad.roas}</td>
                                    <td>
                                      <button
                                        className={S.popupBtn}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleOpenAdPopup(ad.adId);
                                        }}
                                      >
                                        자세히 보기
                                      </button>
                                    </td>
                                    <td>{ad.impressions?.toLocaleString()}</td>
                                    <td>{ad.ctr?.toLocaleString()}</td>
                                    <td>{ad.clicks?.toLocaleString()}</td>
                                    <td>{ad.cpc?.toLocaleString()}</td>
                                    <td>{ad.cpm?.toLocaleString()}</td>
                                    <td>
                                      {ad.revenuePerPurchase?.toLocaleString()}
                                    </td>
                                    <td>{ad.purchaseRatio}</td>
                                    <td>
                                      <div className={S.commentWrapper}>
                                        <button
                                          className={S.editCommentButton}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleOpenCommentPopup(ad.adId, ad.memo);
                                          }}
                                        >
                                          메모 확인
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {isAdPopupOpen && popupUsedAdId && (
        <SafeAdCreativePopup
          adId={popupUsedAdId}
          from={dayjs(filter?.endDate).subtract(6, 'day').format('YYYY-MM-DD')}
          to={filter?.endDate}
          onClose={() => {
            setIsAdPopupOpen(false);
            setPopupUsedId('');
          }}
        />
      )}

      {isAdGroupPopupOpen && popupUsedAdGroupId && (
        <SafeAdGroupCreativePopup
          adGroupId={popupUsedAdGroupId}
          from={dayjs(filter?.endDate).subtract(6, 'day').format('YYYY-MM-DD')}
          to={filter?.endDate}
          onClose={() => {
            setIsAdGroupPopupOpen(false);
            setPopupUsedAdGroupId('');
          }}
        />
      )}

      {commentPopupData && (
        <CommentPopup
          adId={commentPopupData.adId}
          initialComment={commentPopupData.memo}
          onClose={handleCloseCommentPopup}
        />
      )}
    </div>
  )
}

export default TikTokAdGroupData
