import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { AuthorizationTokenAtom } from '../../../../../../recoil/Authorization.recoil';
import { GoogleAdAccountType } from '../../../../../../types/LoginTypes';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import S from './GoogleDataSearch.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

interface GoogleDataFilterType {
  customerId: string;
  startDate: string;
  endDate: string;
}

interface GoogleDataSearchProps {
  googleAccounts: GoogleAdAccountType[];
  filters: GoogleDataFilterType;
  setFilters: React.Dispatch<React.SetStateAction<GoogleDataFilterType>>;
  handleSearch: (filters: GoogleDataFilterType) => void;
}

const GoogleDataSearch = (props: GoogleDataSearchProps) => {
  const token = useRecoilValue(AuthorizationTokenAtom);
  const { googleAccounts, setFilters, filters, handleSearch } = props;
  const [accounts, setAccounts] = useState<{customerId: string, name: string}[]>([]);
  const [startDate, setStartDate] = useState<Date>(dayjs().subtract(1, 'day').toDate());
  const [endDate, setEndDate] = useState<Date>(dayjs().toDate());

  // 필수 필드의 유효성 상태
  const [isAccountIdValid, setIsAccountIdValid] = useState<boolean>(true);
  const [isStartDateValid, setIsStartDateValid] = useState<boolean>(true);
  const [isEndDateValid, setIsEndDateValid] = useState<boolean>(true);
  const [alert, setAlert] = useState<{message: string, type: 'warning' | 'info'} | null>(null);

  useEffect(() => {
    if (googleAccounts?.length > 0) {
      setAccounts(googleAccounts.map((account) => ({
        customerId: account.customerId,
        name: account.name
      })));
      setFilters({
        customerId: googleAccounts[0]?.customerId || '',
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
      });
    }
  }, [googleAccounts]); // googleAccounts가 변경될 때마다 실행

  // 알림 표시 함수
  const showAlert = (message: string, type: 'warning' | 'info' = 'info') => {
    setAlert({ message, type });
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  const validateFields = () => {
    const isValidAccountId = !!filters.customerId;
    const isValidStartDate = !!filters.startDate;
    const isValidEndDate = !!filters.endDate;

    setIsAccountIdValid(isValidAccountId);
    setIsStartDateValid(isValidStartDate);
    setIsEndDateValid(isValidEndDate);

    return isValidAccountId && isValidStartDate && isValidEndDate;
  };

  const handleSearchClick = () => {
    if (validateFields()) {
      const startDate = dayjs(filters.startDate);
      const endDate = dayjs(filters.endDate);
      const diffDays = endDate.diff(startDate, 'day');

      if (diffDays > 7) {
        showAlert('7일 이상의 기간은 검색할 수 없습니다. 날짜를 다시 선택해주세요.', 'warning');
        return;
      }

      handleSearch(filters);
    }
  };

  return (
    <>
      {alert && (
        <div className={`${S.alert} ${S[alert.type]}`}>
          <span className={S.message}>{alert.message}</span>
        </div>
      )}
      
      <div className={S.searchContainer}>
        <h2>필터</h2>
        
        {/* 기본 정보 섹션 */}
        <div className={S.searchGroup}>
          <div className={S.flexContainer}>
            <div className={`${S.searchItem} ${!isAccountIdValid && S.invalidField}`}>
              <label>계정</label>
              <select
                value={filters.customerId}
                onChange={(e) => setFilters(prev => ({ ...prev, customerId: e.target.value }))}
              >
                {accounts.map((account) => (
                  <option key={account.customerId} value={account.customerId}>
                    {account.name}
                  </option>
                ))}
              </select>
              {!isAccountIdValid && <small className={S.errorText}>계정을 선택해주세요</small>}
            </div>
          </div>
        </div>

        {/* 날짜 선택 및 검색 버튼 */}
        <div className={S.searchFooter}>
          <div className={S.datePicker}>
            <div className={S.datePickerItem}>
              <label>조회기간</label>
              <DatePicker
                selected={startDate}
                onChange={(date: Date | null) => {
                  if (date) {
                    setStartDate(date);
                    setFilters(prev => ({
                      ...prev,
                      startDate: dayjs(date).format('YYYY-MM-DD'),
                    }));
                  }
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                className={S.customDatePicker}
                dateFormat="yyyy-MM-dd"
              />
              <span className={S.datePickerSeparator}>~</span>
              <DatePicker
                selected={endDate}
                onChange={(date: Date | null) => {
                  if (date) {
                    setEndDate(date);
                    setFilters(prev => ({
                      ...prev,
                      endDate: dayjs(date).format('YYYY-MM-DD'),
                    }));
                  }
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                className={S.customDatePicker}
                dateFormat="yyyy-MM-dd"
              />
            </div>
            <button onClick={handleSearchClick} className={S.searchBtn}>
              검색
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleDataSearch; 