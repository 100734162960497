import { useRecoilState } from 'recoil'
import { AuthorizationTokenAtom, MyInfoAtom } from '../recoil/Authorization.recoil'
import { useEffect } from 'react'
import _ from 'lodash'
import { AuthUtils } from '../utils/AuthUtils'
import { fetchUsersMe } from '../repository/LoginRepository'
import { useNavigate } from 'react-router-dom'
import { fetchMetaAccounts } from '../repository/MetaDataRepository'
import { MetaAccountsAtom, TikTokAccountsAtom, GoogleAdAccountsAtom } from '../recoil/Data.recoil'
import { fetchTikTokAccounts } from '../repository/TikTokDataRepository'
import { fetchGoogleAccounts } from '../repository/GoogleDataRepository'

export const UserInfoService = () => {
  const navigate = useNavigate();
  const [token, setToken] = useRecoilState(AuthorizationTokenAtom);
  const [myInfo, setMyInfo] = useRecoilState(MyInfoAtom);
  const [metaAccounts, setMetaAccounts] = useRecoilState(MetaAccountsAtom);
  const [tikTokAccounts, setTikTokAccounts] = useRecoilState(TikTokAccountsAtom);
  const [googleAccounts, setGoogleAccounts] = useRecoilState(GoogleAdAccountsAtom);

  const basicInfoSetting = (token: string) => {
    fetchUsersMe(token)
      .then((res) => {
        if (res.status === 'NORMAL') {
          setMyInfo(res);
        } else {
          AuthUtils.removeCookie('token');
          AuthUtils.needLogin(navigate);
          console.log('인증 에러발생');
        }
      })
      .catch((error) => {
        AuthUtils.removeCookie('token');
        AuthUtils.needLogin(navigate);
        console.error(error);
      });
    fetchMetaAccounts(token)
      .then((res) => {
        setMetaAccounts(res);
      })
      .catch((error) => console.error(error));
    fetchTikTokAccounts(token)
      .then((res) => {
        setTikTokAccounts(res);
      })
      .catch((error) => console.error(error));
    fetchGoogleAccounts(token)
      .then((res) => {
        setGoogleAccounts(res);
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    const tokenFromCookie = AuthUtils.getCookie('token');
    if (_.isEmpty(token)) {
      if (_.isEmpty(tokenFromCookie)) {
        AuthUtils.needLogin(navigate);
      } else {
        basicInfoSetting(tokenFromCookie)
        setToken(tokenFromCookie);
      }
    } else {
      if(metaAccounts?.length === 0 || tikTokAccounts?.length === 0 || googleAccounts?.length === 0 || myInfo?.id === undefined){
        basicInfoSetting(tokenFromCookie)
      }
    }
  }, [token]);
}