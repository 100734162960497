import React, { useEffect, useState } from 'react'
import { FaSyncAlt } from 'react-icons/fa'
import Switch from 'react-switch'
import 'react-datepicker/dist/react-datepicker.css'
import S from './MetaSetsData.module.scss'
import {
  AD_STATUS,
  MetaAdType,
  MetaSetsType,
  MetaDataFilterType,
  MetaStatusEditType,
  SortingType,
} from '../../../../../types/MetaDataTypes'
import { UserInfoService } from '../../../../../service/UserInfoService'
import {
  AuthorizationTokenAtom,
  MyInfoAtom,
} from '../../../../../recoil/Authorization.recoil'
import { useRecoilState, useRecoilValue } from 'recoil'
import _ from 'lodash'
import MetaDataSearch from '../MetaDataSearch'
import {
  changeAdStatus,
  changeSetsDailyBudget,
  changeSetsStatus, fetchMetaAccounts,
  fetchMetaSetsData,
  metaBulkChangeSetsDailyBudget,
  metaBulkChangeSetsStatus, syncDataManually,
} from '../../../../../repository/MetaDataRepository'
import dayjs from 'dayjs'
import { MetaAccountsAtom } from '../../../../../recoil/Data.recoil'
import AdCreativePopup from '../AdCreativePopup'
import SetsCreativePopup from '../SetsCreativePopup'
import CommentPopup from '../CommentPopup'

const MetaSetsData = () => {
  UserInfoService()

  const token = useRecoilValue(AuthorizationTokenAtom)
  const myInfo = useRecoilValue(MyInfoAtom)
  const [metaAccounts, setMetaAccounts] = useRecoilState(MetaAccountsAtom);
  const [data, setData] = useState<MetaSetsType[]>([])
  const [expandedRowData, setExpandedRowData] = useState<{
    [key: number]: MetaAdType[]
  }>({})
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null)
  const [filter, setFilters] = useState<MetaDataFilterType>(
    {} as MetaDataFilterType,
  )
  const [totalElements, setTotalElements] = useState(0)
  const [bulkBudget, setBulkBudget] = useState<number>(0) // 일괄 예산 조정을 위한 상태 추가
  const [bulkStatus, setBulkStatus] = useState<AD_STATUS>('') // 일괄 ON/OFF 조정을 위한 상태 추가
  const [sortConfig, setSortConfig] = useState({
    key: 'spend',
    direction: 'asc',
  } as SortingType)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [popupUsedAdId, setPopupUsedId] = useState<string>('')
  const [popupUsedSetsId, setPopupUsedSetsId] = useState<string>('')
  const [isAdPopupOpen, setIsAdPopupOpen] = useState(false)
  const [isSetsPopupOpen, setIsSetsPopupOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const [commentPopupData, setCommentPopupData] = useState<{adId: string, memo: string} | null>(null)

  useEffect(() => {
    const ids = metaAccounts?.map((it) => it.metaAccountId)
    if (!_.isEmpty(token) && ids?.length > 0) {
      const campaignIds = metaAccounts[0].campaigns?.map((it) => it.campaignId)
      if (campaignIds?.length > 0) {
        const initFilter = {
          metaAccountId: ids[0],
          campaignId: campaignIds[0],
          setId: '',
          setName: '',
          startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
        } as MetaDataFilterType
        setFilters((prev) => initFilter)
        fetchData(initFilter)
      }
    }
  }, [myInfo, metaAccounts])

  const handleRowMouseEnter = (rowIndex: number) => {
    setHoveredRowIndex(rowIndex);
  };

  const handleRowMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleSyncClick = () => {
    if(myInfo.adAccount.tier !== 'PREMIUM'){
      alert('프리미엄 티어 이상부터 사용가능 합니다.')
      return
    }

    setIsSyncing(true) // 동기화 중 상태 true
    syncDataManually(token, filter.startDate, filter.endDate)
      .then((res) => {
        if(res.success){
          alert('동기화 성공!')
          fetchMetaAccounts(token)
            .then((res) => {
              setMetaAccounts(res);
            })
            .catch((error) => console.error(error));
        }else{
          alert(`동기화 실패. \n${res.message}`)
        }
      })
      .catch((e) => {
        // 실패 시 처리
        console.error(e)
        alert('동기화 실패. \n관리자에게 문의하세요.')
      })
      .finally(() => {
        setIsSyncing(false) // 동기화 중 상태 false
      })
  }

  const getLatestSyncTime = () => {
    const successfulSyncs = metaAccounts[0]?.latestSyncHistories?.filter(
      (history) => history.status === 'SUCCESS',
    )
    if (successfulSyncs?.length > 0) {
      const latestSync = _.maxBy(successfulSyncs, (sync) =>
        dayjs(sync.updatedAt).valueOf(),
      )
      return latestSync?.updatedAt
        ? dayjs(latestSync.updatedAt).format('YYYY-MM-DD HH:mm:ss')
        : null
    }
    return null
  }

  const latestSyncTime = getLatestSyncTime()
  const isSyncSuccessful = latestSyncTime !== null

  const handleCheckboxChange = (setId: string) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(setId)
        ? prevSelected.filter((id) => id !== setId)
        : [...prevSelected, setId],
    )
  }

  // 전체 선택 체크박스 핸들러
  const handleSelectAll = () => {
    if (selectedRows.length === sortedData.length) {
      setSelectedRows([]) // 모두 선택된 상태에서 다시 클릭하면 모두 해제
    } else {
      setSelectedRows(sortedData.map((row) => row.setId)) // 전체 선택
    }
  }

  const convertStatusOpposite = (status: string): AD_STATUS => {
    return status === 'ACTIVE' ? 'PAUSED' : 'ACTIVE'
  }

  const handleAdSwitchChange = (
    adId: string,
    adName: string,
    status: AD_STATUS,
  ) => {
    const action = status === 'ACTIVE' ? 'off' : 'on';
    const message = `해당 광고 ${action} 하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');
    setIsLoading(true);

    if (memo != null) {
      changeAdStatus(token, adId, convertStatusOpposite(status), memo)
        .then((res) => {
          if (res.success) {
            alert(`[AD] ${adName} ${action === 'on' ? 'ON 성공' : 'OFF 성공'}`);
            handleSearch(filter);
          } else {
            alert(
              `[AD] ${adName} ${action === 'on' ? 'ON 실패' : 'OFF 실패'}\n 관리자 문의 부탁드립니다.`,
            );
          }
          setIsLoading(false);
        })
        .catch((e) => console.error(e));
    }
  };

  const updateSetsStatus = (
    setId: string,
    setName: string,
    status: AD_STATUS,
  ) => {
    const action = status === 'ACTIVE' ? 'off' : 'on';
    const message = `해당 세트를 ${action} 하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');
    setIsLoading(true);

    if (memo != null) {
      changeSetsStatus(token, setId, convertStatusOpposite(status), memo)
        .then((res) => {
          if (res.success) {
            alert(`[SETS] ${setName} ${action === 'on' ? 'ON 성공' : 'OFF 성공'}`);
            setSelectedRows([]); // 체크박스 해제
            setBulkStatus(''); // Bulk 상태 초기화
            handleSearch(filter);
          } else {
            alert(`[SETS] ${setName} ${action === 'on' ? 'ON 실패' : 'OFF 실패'}\n 관리자 문의 부탁드립니다.`);
          }
          setIsLoading(false);
        })
        .catch((e) => console.error(e));
    }
  };

  const updateBudget = (
    setId: string,
    setName: string,
    changedBudget: number,
  ) => {
    const message = `해당 세트의 예산을 ${changedBudget}로 설정하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');
    setIsLoading(true);

    if (memo != null) {
      changeSetsDailyBudget(token, setId, changedBudget, memo)
        .then((res) => {
          if (res.success) {
            alert(`[SETS] ${setName} 예산변경 성공`);
            setSelectedRows([]); // 체크박스 해제
            setBulkStatus(''); // Bulk 상태 초기화
            handleSearch(filter);
          } else {
            alert(`[SETS] ${setName} 예산변경 실패\n 관리자 문의 부탁드립니다.`);
          }
          setIsLoading(false)
        })
        .catch((e) => console.error(e));
    }
  };


  const fetchData = (filter: MetaDataFilterType) => {
    setIsLoading(true)
    const queryParams = [
      `metaAccountId=${filter.metaAccountId}`,
      filter.campaignId ? `campaignId=${filter.campaignId}` : '',
      filter.setId ? `setId=${filter.setId}` : '',
      filter.setName ? `setName=${filter.setName}` : '',
      filter.setStatus ? `setStatus=${filter.setStatus}` : '',
      filter.goeSpend !== undefined ? `goeSpend=${filter.goeSpend}` : '',
      filter.loeSpend !== undefined ? `loeSpend=${filter.loeSpend}` : '',
      `from=${filter.startDate}`,
      `to=${filter.endDate}`,
    ]
      .filter(Boolean) // 빈 문자열을 제거합니다.
      .join('&') // '&'로 쿼리 파라미터를 연결합니다.

    fetchMetaSetsData(token, queryParams)
      .then((res) => {
        const updatedData = res.map((item) => ({
          ...item,
          changedDailyBudget: item.dailyBudget,
          ads: item.ads
        }))
        setData(updatedData)
        setTotalElements(updatedData.length)
      })
      .catch((e) => {
        console.error(e.message)
        alert('데이터 조회 실패!')
      })
      .finally(() => {
        setIsLoading(false) // 로딩 끝
      })
  }

  const handleBudgetChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    rowIndex: number,
  ) => {
    const inputValue = e.target.value

    // 숫자만 입력되게 하고, 음수 및 0 미만의 값을 막는 로직
    if (!/^\d*\.?\d*$/.test(inputValue) || parseFloat(inputValue) < 0) {
      alert('숫자만 입력 가능하며, 음수는 허용되지 않습니다.')
      return
    }

    const newBudget = parseFloat(inputValue)

    if (isNaN(newBudget) || newBudget < 0) {
      alert('유효한 예산을 입력해주세요. 0 이상의 숫자만 가능합니다.')
      return
    }

    const selectedSetId = sortedData[rowIndex].setId

    setData((prevData) => {
      // prevData에서 동일한 setId를 가진 모든 항목을 업데이트
      const newData = prevData.map((item) => {
        if (item.setId === selectedSetId) {
          return {
            ...item,
            changedDailyBudget: newBudget,
          }
        }
        return item
      })

      return newData
    })
  }

  const handleRowClick = (expandedRowIndex: number | null, rowIndex: number) => {
    if (expandedRowIndex === rowIndex) {
      setExpandedRowIndex(null)
      setExpandedRowData((prev) => {
        const newState = { ...prev }
        delete newState[rowIndex]
        return newState
      })
    } else {
      const selectedRowData = sortedData[rowIndex]?.ads.sort((a, b) => {
        const key = sortConfig.key as keyof MetaAdType
        if (a[key] < b[key]) {
          return sortConfig.direction === 'asc' ? -1 : 1
        }
        if (a[key] > b[key]) {
          return sortConfig.direction === 'asc' ? 1 : -1
        }
        return 0
      })

      setExpandedRowIndex(rowIndex)
      setExpandedRowData({ [rowIndex]: selectedRowData })
    }
  }

  const handleSearch = (filter: MetaDataFilterType) => {
    setExpandedRowIndex(null);
    fetchData(filter)
  }

  const handleToggleClick = (e: React.MouseEvent) => {
    e.stopPropagation() // Row 클릭 이벤트로부터 이벤트 버블링을 막습니다.
  }

  const handleBulkStatusChange = () => {
    if (selectedRows.length === 0) {
      alert('선택된 항목이 없습니다.');
      return;
    }

    const action = bulkStatus === 'ACTIVE' ? '활성화' : '비활성화';
    const message = `선택된 세트를 ${action} 하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');

    if (memo != null) {
      setIsLoading(true);
      const requestPayload = data
        .filter((item) => selectedRows.includes(item.setId))
        .map(
          (item) =>
            ({
              id: item.setId,
              status: bulkStatus,
              memo: memo, // memo를 각 요청에 포함
            } as MetaStatusEditType),
        );

      metaBulkChangeSetsStatus(token, requestPayload)
        .then((responses) => {
          const failedIds = responses
            .filter((res) => !res.success)
            .map((res) => res.id);

          if (failedIds.length === 0) {
            alert('일괄 상태 변경 성공!');
          } else {
            alert(
              `일괄 상태 변경 실패! 실패한 ID: ${failedIds.join(', ')}\n 관리자에게 문의해주세요.`,
            );
          }

          setSelectedRows([]); // 체크박스 해제
          setBulkStatus(''); // Bulk 상태 초기화
          handleSearch(filter);
          setIsLoading(false);
        })
        .catch((e) => console.error(e));
    }
  };


  const handleBulkBudgetChange = () => {
    if (selectedRows.length === 0) {
      alert('선택된 항목이 없습니다.')
      return
    }

    const message = `선택된 세트의 예산을 ${bulkBudget}로 설정하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');

    if (window.confirm(message)) {
      setIsLoading(true);
      const requestPayload = data
        .filter((item) => selectedRows.includes(item.setId))
        .map((item) => ({ id: item.setId, dailyBudget: bulkBudget, memo: memo }))
      metaBulkChangeSetsDailyBudget(token, requestPayload)
        .then((responses) => {
          const failedIds = responses
            .filter((res) => !res.success)
            .map((res) => res.id)

          if (failedIds.length === 0) {
            alert('일괄 예산 변경 성공!')
          } else {
            alert(
              `일괄 예산 변경 실패! 실패한 ID: ${failedIds.join(', ')}\n 관리자에게 문의해주세요.`,
            )
          }

          setSelectedRows([]); // 체크박스 해제
          setBulkStatus(''); // Bulk 상태 초기화
          handleSearch(filter)
          setIsLoading(false);
        })
        .catch((e) => console.error(e))
    }
  }

  const handleBudgetEdit = (
    type: 'increase' | 'decrease',
    rowIndex: number,
  ) => {
    let percent = type === 'increase' ? 1.2 : 0.8

    // 선택된 setId를 가져옴
    const selectedSetId = sortedData[rowIndex].setId

    setData((prevData) => {
      // prevData에서 동일한 setId를 가진 모든 항목을 업데이트
      const newData = prevData.map((item) => {
        if (item.setId === selectedSetId) {
          // 예산을 곱하고, lodash의 round 함수를 사용해 정수로 반올림
          return {
            ...item,
            changedDailyBudget: _.round(item.changedDailyBudget * percent),
          }
        }
        return item
      })

      // 정렬 없이 그대로 반환
      return newData
    })
  }

  const sortedData = React.useMemo(() => {
    if (sortConfig.key) {
      const sorted = [...data].sort((a, b) => {
        const key = sortConfig.key as keyof MetaSetsType
        if (a[key] < b[key]) {
          return sortConfig.direction === 'asc' ? -1 : 1
        }
        if (a[key] > b[key]) {
          return sortConfig.direction === 'asc' ? 1 : -1
        }
        return 0
      })

      return sorted
    }
    return data
  }, [data, sortConfig])

  const requestSort = (key: string) => {
    let direction = 'asc'
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    setSortConfig({ key, direction })
    //펼쳐져 있는것 닫기
    setExpandedRowIndex(null)
  }

  const handleOpenAdPopup = (adId: string) => {
    setPopupUsedId(adId) // adId만 저장
    setIsAdPopupOpen(true) // 팝업 열기
  }

  const handleOpenSetsPopup = (setsId: string) => {
    setPopupUsedSetsId(setsId) // adId만 저장
    setIsSetsPopupOpen(true) // 팝업 열기
  }

  const handleClosePopup = (type: 'SETS' | 'AD') => {
    if (type === 'SETS') {
      setIsSetsPopupOpen(false)
    } else {
      setIsAdPopupOpen(false) // 팝업 닫기
    }
  }

  const handleOpenCommentPopup = (adId: string, memo: string) => {
    setCommentPopupData({ adId, memo }); // 코멘트 팝업 열기
  };

  const handleCloseCommentPopup = (type: 'EDIT' | 'CANCEL') => {
    setCommentPopupData(null);
    if(type == 'EDIT'){
      fetchData(filter);
    }
  };

  useEffect(() => {
    const expandedRowNumberIndex = expandedRowIndex;
    if (expandedRowNumberIndex != null) {
      handleRowClick(null, expandedRowNumberIndex)
    }
  }, [sortedData]);

  if (metaAccounts.length === 0) {
    return <></>
  }

  return (
    <div className={S.container}>
      <div className={S.pageHeader}>
        <h1>Meta 광고 관리</h1>
        <p className={S.pageDescription}>광고 세트 및 광고 데이터를 관리합니다</p>
      </div>
  
      <div className={S.searchSection}>
        <MetaDataSearch
          metaAccounts={metaAccounts || []}
          filters={filter}
          setFilters={setFilters}
          handleSearch={handleSearch}
          searchType={'SETS'}
        />
      </div>
  
      {totalElements > 0 && (
        <div className={S.uploadSection}>
          <div className={S.bulkControls}>
            <div className={S.bulkGroup}>
              <div className={S.bulkItem}>
                <label>광고 세트 상태 일괄 변경</label>
                <div className={S.buttonGroup}>
                  <button
                    onClick={() => setBulkStatus('ACTIVE')}
                    className={`${S.statusButton} ${bulkStatus === 'ACTIVE' ? S.active : ''}`}
                  >
                    전체 ON
                  </button>
                  <button
                    onClick={() => setBulkStatus('PAUSED')}
                    className={`${S.statusButton} ${bulkStatus === 'PAUSED' ? S.active : ''}`}
                  >
                    전체 OFF
                  </button>
                  <button 
                    onClick={handleBulkStatusChange} 
                    className={S.applyButton}
                    disabled={!bulkStatus || selectedRows.length === 0}
                  >
                    적용
                  </button>
                </div>
              </div>

              <div className={S.bulkItem}>
                <label>예산 일괄 조정</label>
                <div className={S.buttonGroup}>
                  <input
                    type="number"
                    className={S.budgetInput}
                    value={bulkBudget}
                    onChange={(e) => setBulkBudget(Number(e.target.value))}
                    placeholder="예산 입력"
                  />
                  <button
                    onClick={handleBulkBudgetChange}
                    className={S.applyButton}
                    disabled={!bulkBudget || selectedRows.length === 0}
                  >
                    적용
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
  
      <div className={S.dataSection}>
        <div className={S.dataInfo}>
          <div className={S.totalCount}>
            총 {totalElements.toLocaleString()}개의 데이터
          </div>
          <div className={S.syncInfo}>
            <div className={S.syncStatus}>
              <span className={isSyncSuccessful ? S.syncSuccess : S.syncError}>
                {isSyncSuccessful ? '동기화 완료' : '동기화 필요'}
              </span>
              {isSyncSuccessful && (
                <span className={S.syncTime}>최근 동기화: {latestSyncTime}</span>
              )}
            </div>
            <button
              className={`${S.syncButton} ${isSyncing ? S.syncing : ''}`}
              onClick={handleSyncClick}
              disabled={isSyncing}
            >
              {isSyncing ? <FaSyncAlt className={S.syncIcon} /> : '수동 동기화'}
            </button>
          </div>
        </div>
  
        {isLoading ? (
          <div className={S.loadingWrapper}>
            <div className={S.loader}></div>
            <span>데이터를 불러오는 중...</span>
          </div>
        ) : totalElements === 0 ? (
          <div className={S.noData}>데이터가 없습니다.</div>
        ) : (
          <div className={S.tableWrapper}>
            <table className={S.table}>
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectedRows.length === sortedData.length}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th>광고 SET ON/OFF</th>
                  <th>광고 예산 조정</th>
                  <th>Insights</th>
                  <th>캠페인 명</th>
                  <th>광고 세트명</th>
                  <th>일일 예산</th>
                  <th onClick={() => requestSort('spend')}>
                    지출 {sortConfig.key === 'spend' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : null}
                  </th>
                  <th>ROAS</th>
                  <th>매출</th>
                </tr>
              </thead>
              <tbody>
                {sortedData?.map((row, rowIndex) => {
                  const isExpanded = rowIndex === expandedRowIndex;
                  const isHovered = hoveredRowIndex === rowIndex;
                  return (
                    <React.Fragment key={`${row.campaignId}-${row.setId}`}>
                      <tr
                        onClick={() => handleRowClick(expandedRowIndex, rowIndex)}
                        onMouseEnter={() => handleRowMouseEnter(rowIndex)}
                        onMouseLeave={handleRowMouseLeave}
                        className={`${S.tableRow} ${isExpanded ? S.expanded : ''} ${isHovered ? S.hovered : ''}`}
                      >
                        <td className={S.checkboxCell}>
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(row.setId)}
                            onChange={() => handleCheckboxChange(row.setId)}
                            onClick={handleToggleClick}
                          />
                        </td>
                        <td className={S.switchCell}>
                          <div onClick={handleToggleClick}>
                        <Switch
                          checked={row.status === 'ACTIVE'}
                          onChange={() => updateSetsStatus(row.setId, row.name, row.status)}
                          onColor="#2e7d32"          // success-color와 동일
                          offColor="#e0e0e0"         // border-color와 동일
                          onHandleColor="#ffffff"    // 흰색
                          offHandleColor="#ffffff"   // 흰색
                          handleDiameter={24}        // 핸들 크기 조정
                          uncheckedIcon={false}      // 아이콘 제거
                          checkedIcon={false}        // 아이콘 제거
                          height={16}                // 전체 높이
                          width={36}                 // 전체 너비
                          className="react-switch"
                        />
                          </div>
                        </td>
                        <td>
                          <div className={S.budgetControls} onClick={(e) => e.stopPropagation()}>
                            <input
                              type="number"
                              value={row.changedDailyBudget}
                              onChange={(e) => handleBudgetChange(e, rowIndex)}
                              className={S.budgetInput}
                            />
                            <div className={S.budgetButtons}>
                              <button
                                className={S.budgetBtn}
                                onClick={() => updateBudget(row.setId, row.name, row.changedDailyBudget)}
                              >
                                예산 적용
                              </button>
                              <button
                                className={`${S.percentButton} ${S.increase}`}
                                onClick={() => handleBudgetEdit('increase', rowIndex)}
                              >
                                <span className={S.icon}>↑</span>
                                20%
                              </button>
                              <button
                                className={`${S.percentButton} ${S.decrease}`}
                                onClick={() => handleBudgetEdit('decrease', rowIndex)}
                              >
                                <span className={S.icon}>↓</span>
                                20%
                              </button>
                            </div>
                          </div>
                        </td>
                        <td>
                          <button
                            className={S.insightButton}
                            onClick={() => handleOpenSetsPopup(row.setId)}
                          >
                            자세히 보기
                          </button>
                        </td>
                        <td>{row.campaignName}</td>
                        <td>{row.name}</td>
                        <td>{row.dailyBudget.toLocaleString()}</td>
                        <td>{row.spend.toLocaleString()}</td>
                        <td>{row.roas}</td>
                        <td>{row.revenue.toLocaleString()}</td>
                      </tr>
                      {isExpanded && (
                        <tr className={S.expandedRow}>
                          <td colSpan={10}>
                            <div className={S.expandedContent}>
                              <table className={S.innerTable}>
                                <thead>
                                  <tr>
                                    <th>광고 ON/OFF</th>
                                    <th>광고명</th>
                                    <th>지출</th>
                                    <th>매출</th>
                                    <th>ROAS</th>
                                    <th>소재 및 Insights</th>
                                    <th>노출</th>
                                    <th>CTR</th>
                                    <th>클릭 수</th>
                                    <th>CPC</th>
                                    <th>CPM</th>
                                    <th>구매당 매출</th>
                                    <th>전환율</th>
                                    <th>메모</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {expandedRowData[rowIndex]?.map((ad) => (
                                    <tr key={ad.adId}>
                                      <td>
                                      <Switch
                                        checked={ad.status === 'ACTIVE'}
                                        onChange={() => handleAdSwitchChange(ad.adId, ad.name, ad.status)}
                                        onColor="#2e7d32"          // ON 상태: 초록색
                                        offColor="#d32f2f"         // OFF 상태: 빨간색
                                        onHandleColor="#ffffff"
                                        offHandleColor="#ffffff"
                                        handleDiameter={20}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={24}
                                        width={48}
                                        activeBoxShadow="0 0 2px 3px rgba(46, 125, 50, 0.2)"  // ON 상태 시 초록색 그림자
                                        className="react-switch"
                                      />
                                      </td>
                                      <td>{ad.name}</td>
                                      <td>{ad.spend?.toLocaleString()}</td>
                                      <td>{ad.revenue?.toLocaleString()}</td>
                                      <td>{ad.roas}</td>
                                      <td>
                                        <button
                                          className={S.insightButton}
                                          onClick={() => handleOpenAdPopup(ad.adId)}
                                        >
                                          자세히 보기
                                        </button>
                                      </td>
                                      <td>{ad.impressions?.toLocaleString()}</td>
                                      <td>{ad.ctr?.toLocaleString()}</td>
                                      <td>{ad.clicks?.toLocaleString()}</td>
                                      <td>{ad.cpc?.toLocaleString()}</td>
                                      <td>{ad.cpm?.toLocaleString()}</td>
                                      <td>{ad.revenuePerPurchase?.toLocaleString()}</td>
                                      <td>{ad.purchaseRatio}</td>
                                      <td>
                                        <button
                                          className={S.commentButton}
                                          onClick={() => handleOpenCommentPopup(ad.adId, ad.memo)}
                                        >
                                          메모 확인
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
  
      {isAdPopupOpen && !_.isEmpty(popupUsedAdId) && (
        <AdCreativePopup
          adId={popupUsedAdId}
          from={dayjs(filter?.endDate).subtract(6, 'day').format('YYYY-MM-DD')}
          to={filter?.endDate}
          onClose={() => handleClosePopup('AD')}
        />
      )}
      
      {isSetsPopupOpen && !_.isEmpty(popupUsedSetsId) && (
        <SetsCreativePopup
          setId={popupUsedSetsId}
          from={dayjs(filter?.endDate).subtract(6, 'day').format('YYYY-MM-DD')}
          to={filter?.endDate}
          onClose={() => handleClosePopup('SETS')}
        />
      )}
  
      {commentPopupData && (
        <CommentPopup
          adId={commentPopupData.adId}
          initialComment={commentPopupData.memo}
          onClose={handleCloseCommentPopup}
        />
      )}
    </div>
  );
}

export default MetaSetsData