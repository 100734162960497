import React, { useState, useEffect } from 'react'
import S from './SetsCreativePopup.module.scss'
import { IoChevronDownOutline, IoChevronUpOutline, IoEyeOutline } from 'react-icons/io5'
import {
  fetchMetaSetsDetails,
  fetchSetsHistories,
} from '../../../../../repository/MetaDataRepository'
import { useRecoilState, useRecoilValue } from 'recoil'
import { AuthorizationTokenAtom } from '../../../../../recoil/Authorization.recoil'
import {
  AdHistoryType,
  MetaAdInsightType,
  MetaSetsDetailType,
  MetaAdCreativeType,
  MetaAdDetailType,
} from '../../../../../types/MetaDataTypes'
import { MetaAccountsAtom } from '../../../../../recoil/Data.recoil'
import dayjs from 'dayjs'

interface SetsCreativePopupProps {
  setId: string
  from: string
  to: string
  onClose: (type: string) => void
}

interface AdCreativeType {
  body?: string;
  link?: string;
  imageUrl?: string;
}

interface SelectedAdType {
  adId: string;
  insights: MetaAdInsightType[];
  name?: string;
  status?: string;
  adObject?: AdCreativeType;
}

const SetsCreativePopup: React.FC<SetsCreativePopupProps> = ({
  setId,
  from,
  to,
  onClose,
}) => {
  const token = useRecoilValue(AuthorizationTokenAtom)
  const [setsDetail, setSetsDetail] = useState<MetaSetsDetailType | null>(null)
  const [selectedAd, setSelectedAd] = useState<SelectedAdType | null>(null)
  const [setsHistories, setSetsHistories] = useState<AdHistoryType[]>([])
  const [metaAccounts, setMetaAccounts] = useRecoilState(MetaAccountsAtom)
  const [showHistory, setShowHistory] = useState(false)
  const [showAdList, setShowAdList] = useState(true)
  const [showCreativeDetail, setShowCreativeDetail] = useState<string | null>(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    fetchMetaSetsDetails(token, setId, from, to).then((data) => {
      setSetsDetail(data)
    })

    fetchSetsHistories(token, metaAccounts[0].metaAccountId, setId).then(
      (data) => {
        const sortedHistories = data.content.sort(
          (a, b) =>
            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
        )
        setSetsHistories(sortedHistories)
      },
    )
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [setId])

  if (!setsDetail) {
    return <div>Loading...</div>
  }

  if (!setsDetail) {
    return (
      <div className={S.popupOverlay}>
        <div className={S.popupContent}>
          <div className={S.popupHeader}>
            <h2>Set Details</h2>
            <button className={S.closeButton} onClick={() => onClose('AD')}>
              X
            </button>
          </div>
          <div>
            {!setsDetail ? 'Loading...' : '소재를 불러오는데 실패했습니다.'}
          </div>
        </div>
      </div>
    )
  }

  const handleAdClick = (ad: any) => {
    if (selectedAd?.adId === ad.adId) {
      setSelectedAd(null);
    } else {
      const adInsights = setsDetail?.insights
        .flatMap((insight) => insight.adInsights)
        .filter((adInsight) => adInsight.adId === ad.adId) || [];

      setSelectedAd({
        adId: ad.adId,
        insights: adInsights,
        name: ad.name,
        status: ad.status,
        adObject: ad.adObject
      });
    }
  };

  const renderAdInsights = (ad: any) => {
    if (!selectedAd || selectedAd.adId !== ad.adId || !selectedAd.insights?.length) return null;
    
    return (
      <tr className={S.insightRow}>
        <td colSpan={4}>
          <div className={S.adDetailWrapper}>
            <div className={S.actionButtons}>
              <button 
                className={S.viewDetailButton}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowCreativeDetail(ad.adId);
                }}
              >
                <IoEyeOutline />
                광고 소재 자세히 보기
              </button>
            </div>

            {/* Insights Table */}
            <table className={S.insightTable}>
              <thead>
                <tr>
                  <th>Date</th>
                  {fields.map((field) => (
                    <th key={field}>{field}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {selectedAd?.insights.map((insight) => (
                  <tr key={insight.date}>
                    <td>{insight.date}</td>
                    {fields.map((field) => (
                      <td key={field}>
                        {insight[field as keyof MetaAdInsightType] !== null
                          ? insight[field as keyof MetaAdInsightType]
                          : '-'}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    );
  };

  const fields = ['spend', 'impressions', 'ctr', 'cpc', 'cpm', 'roas']

  // Add Creative Detail Modal
  const renderCreativeDetailModal = () => {
    if (!showCreativeDetail) return null;

    const ad = setsDetail?.ads.find(ad => ad.adId === showCreativeDetail);
    if (!ad) return null;

    // Type assertion for adObject
    const adCreative: AdCreativeType = {
      body: ad.adObject?.body,
      link: (ad.adObject as any)?.link,
      imageUrl: (ad.adObject as any)?.imageUrl
    };

    return (
      <div className={S.modalOverlay} onClick={() => setShowCreativeDetail(null)}>
        <div className={S.modalContent} onClick={e => e.stopPropagation()}>
          <div className={S.modalHeader}>
            <h3>광고 소재 상세</h3>
            <button className={S.modalCloseButton} onClick={() => setShowCreativeDetail(null)}>✕</button>
          </div>
          <div className={S.modalBody}>
            <div className={S.adDetailSection}>
              <div className={S.materialSection}>
                <h4>광고 소재</h4>
                <div className={S.adBody}>
                  {adCreative.body || '광고 문구가 없습니다.'}
                </div>
                {adCreative.link && (
                  <a href={adCreative.link} target="_blank" rel="noopener noreferrer" className={S.adLink}>
                    {adCreative.link}
                  </a>
                )}
                {adCreative.imageUrl && (
                  <img 
                    src={adCreative.imageUrl} 
                    alt={ad.name}
                    className={S.adImage}
                  />
                )}
              </div>
              <div className={S.insightSection}>
                <h3>광고 정보</h3>
                <table className={S.insightTable}>
                  <tbody>
                    <tr>
                      <th>광고 ID</th>
                      <td>{ad.adId}</td>
                    </tr>
                    <tr>
                      <th>광고명</th>
                      <td>{ad.name}</td>
                    </tr>
                    <tr>
                      <th>상태</th>
                      <td>{ad.status}</td>
                    </tr>
                    <tr>
                      <th>생성일</th>
                      <td>{ad.createdTime ? dayjs(ad.createdTime).format('YYYY.MM.DD HH:mm') : '-'}</td>
                    </tr>
                    <tr>
                      <th>수정일</th>
                      <td>{ad.updatedTime ? dayjs(ad.updatedTime).format('YYYY.MM.DD HH:mm') : '-'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={S.popupOverlay}>
      <div className={S.popupContent}>
        <div className={S.popupHeader}>
          <h2>Details</h2>
          <button className={S.closeButton} onClick={() => onClose('SETS')}>✕</button>
        </div>

        <div className={S.contentWrapper}>
          {/* Sets Info Section */}
          <div className={S.section}>
            <h3>광고 세트 정보</h3>
            <table className={S.dataTable}>
              <tbody>
                <tr>
                  <th>세트 ID</th>
                  <td>{setsDetail?.setId}</td>
                  <th>상태</th>
                  <td>{setsDetail?.status}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* History Section */}
          {setsHistories.length > 0 && (
            <div className={S.section}>
              <div 
                className={S.sectionHeader}
                onClick={() => setShowHistory(!showHistory)}
              >
                <h3>광고 세트 제어 히스토리</h3>
                <button className={S.toggleButton}>
                  {showHistory ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
                </button>
              </div>
              {showHistory && (
                <table className={S.dataTable}>
                  <thead>
                    <tr>
                      <th>날짜</th>
                      <th>제어 유형</th>
                      <th>User</th>
                      <th>Memo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {setsHistories.map((history) => (
                      <tr key={history.id}>
                        <td>{dayjs(history.updatedAt).format('YYYY.MM.DD HH:mm')}</td>
                        <td>{history.description}</td>
                        <td>{history.userEmail}</td>
                        <td>{history.memo || '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}

          {/* Ad List Section */}
          <div className={S.section}>
            <div 
              className={S.sectionHeader}
              onClick={() => setShowAdList(!showAdList)}
            >
              <h3>광고 목록</h3>
              <button className={S.toggleButton}>
                {showAdList ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
              </button>
            </div>
            {showAdList && (
              <table className={S.dataTable}>
                <thead>
                  <tr>
                    <th>썸네일</th>
                    <th>광고 ID</th>
                    <th>광고명</th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  {setsDetail?.ads.map((ad) => (
                    <React.Fragment key={ad.adId}>
                      <tr 
                        onClick={() => handleAdClick(ad)}
                        className={`${S.clickableRow} ${selectedAd?.adId === ad.adId ? S.selectedRow : ''}`}
                      >
                        <td className={S.thumbnailCell}>
                          {ad.adObject?.imageUrl ? (
                            <img 
                              src={ad.adObject.imageUrl} 
                              alt={ad.name}
                              className={S.thumbnail}
                            />
                          ) : (
                            <div className={S.noThumbnail}>No Image</div>
                          )}
                        </td>
                        <td>{ad.adId}</td>
                        <td>{ad.name}</td>
                        <td>{ad.status}</td>
                      </tr>
                      {renderAdInsights(ad)}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      {renderCreativeDetailModal()}
    </div>
  )
}

export default SetsCreativePopup
