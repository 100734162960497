import React, { useState, useEffect } from 'react'
import S from './AdGroupCreativePopup.module.scss'
import { IoChevronDownOutline, IoChevronUpOutline, IoEyeOutline } from 'react-icons/io5'

import { useRecoilState, useRecoilValue } from 'recoil'
import { AuthorizationTokenAtom } from '../../../../../recoil/Authorization.recoil'
import _ from 'lodash'
import { TikTokAccountsAtom } from '../../../../../recoil/Data.recoil'
import dayjs from 'dayjs'
import { AdHistoryType, TikTokAdGroupDetailType, TikTokAdInsightType, TikTokAdDetailType } from '../../../../../types/TikTokDataTypes'
import { fetchAdGroupHistories, fetchTikTokAdGroupDetails, fetchTikTokAdDetails } from '../../../../../repository/TikTokDataRepository'

interface TikTokCreativePopupProps {
  adGroupId: string
  from: string
  to: string
  onClose: (type: 'AD-GROUP' | 'AD') => void
}

interface SelectedAdType {
  adId: string;
  insights: TikTokAdInsightType[];
  name?: string;
  status?: string;
  updatedTime?: string;
  createdTime?: string;
}

interface TikTokAdType extends TikTokAdDetailType {
  image_url?: string;
  thumbnail_url?: string;
}

interface AdCreativeType {
  title?: string;
  description?: string;
  imageUrl?: string;
  adLink?: string;
}

const AdGroupCreativePopup: React.FC<TikTokCreativePopupProps> = ({
  adGroupId,
  from,
  to,
  onClose,
}) => {
  const token = useRecoilValue(AuthorizationTokenAtom)
  const [adGroupDetail, setAdGroupDetail] = useState<TikTokAdGroupDetailType | null>(null)
  const [selectedAd, setSelectedAd] = useState<SelectedAdType | null>(null)
  const [adGroupHistories, setAdGroupHistories] = useState<AdHistoryType[]>([])
  const [tikTokAccounts, setTikTokAccounts] = useRecoilState(TikTokAccountsAtom)
  const [showHistory, setShowHistory] = useState(false)
  const [showAdList, setShowAdList] = useState(true)
  const [showCreativeDetail, setShowCreativeDetail] = useState<string | null>(null);

  // 사용할 필드 목록 추가
  const fields = ['spend', 'impressions', 'ctr', 'cpc', 'cpm', 'roas'];

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    fetchTikTokAdGroupDetails(token, adGroupId, from, to).then((data) => {
      setAdGroupDetail(data)
    })

    fetchAdGroupHistories(token, tikTokAccounts[0].advertiserId, adGroupId).then(
      (data) => {
        const sortedHistories = data.content.sort(
          (a, b) =>
            new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
        )
        setAdGroupHistories(sortedHistories)
      },
    )
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [adGroupId])

  const handleAdClick = (ad: any) => {
    if (_.isEmpty(selectedAd)) {
      setSelectedAd({
        adId: ad.adId,
        insights: [],
        name: ad.name,
        status: ad.status,
        updatedTime: ad.updatedTime,
        createdTime: ad.createdTime
      });
      // Fetch insights data
      fetchTikTokAdDetails(token, ad.adId, from, to).then((data) => {
        if (data && data.insights) {
          setSelectedAd(prev => prev ? { ...prev, insights: data.insights } : null);
        }
      });
    } else {
      setSelectedAd(null);
    }
  };

  if (!adGroupDetail) {
    return <div>Loading...</div>
  }

  if (!adGroupDetail) {
    return (
      <div className={S.popupOverlay}>
        <div className={S.popupContent}>
          <div className={S.popupHeader}>
            <h2>Ad Group Details</h2>
            <button className={S.closeButton} onClick={() => onClose('AD')}>
              X
            </button>
          </div>
          <div>
            {!adGroupDetail ? 'Loading...' : '소재를 불러오는데 실패했습니다.'}
          </div>
        </div>
      </div>
    )
  }

  const renderAdGroupHistoryTable = () => (
    <table className={S.dataTable}>
      <thead>
        <tr>
          <th>날짜</th>
          <th>제어 유형</th>
          <th>User</th>
          <th>Memo</th>
        </tr>
      </thead>
      <tbody>
        {adGroupHistories.map((history) => (
          <tr key={history.updatedAt}>
            <td>{dayjs(history.updatedAt).format('YYYY.MM.DD HH:mm')}</td>
            <td>{history.description}</td>
            <td>{history.userEmail}</td>
            <td>{history.memo}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

  const renderAdGroupDataTable = () => (
    <table className={S.dataTable}>
      <thead>
        <tr>
          <th>Date</th>
          <th>Name</th>
          <th>Spend</th>
          <th>ROAS</th>
          <th>Revenue</th>
        </tr>
      </thead>
      <tbody>
        {adGroupDetail.insights.map((insight) => (
          <tr key={insight.date}>
            <td>{insight.date}</td>
            <td>{adGroupDetail.name}</td>
            <td>{insight.spend?.toLocaleString()}</td>
            <td>{insight.roas?.toLocaleString()}</td>
            <td>{insight.revenue?.toLocaleString()}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )

  const renderAdDetail = () => {
    if (!selectedAd) return null

    const selectedAdDetail = adGroupDetail.ads.find((ad) => ad.adId === selectedAd.adId)
    const matchingAdInsights = adGroupDetail.insights
      .flatMap((insight) => insight.adInsights)
      .filter((adInsight: TikTokAdInsightType) => adInsight.adId === selectedAd.adId)

    if (!selectedAdDetail || matchingAdInsights.length === 0) return null

    return (
      <>
        <h4 className={S.detailTitle}>
          {selectedAdDetail.name} 소재 및 Insights
        </h4>
        <div className={S.adDetailSection}>
          {/* 왼쪽: 광고 소재 정보 */}
          <div className={S.materialSection}>
            {selectedAdDetail.adObject && (
              <>
                <h4>{selectedAdDetail.adObject.title}</h4>
                <p className={S.adBody}>{selectedAdDetail.adObject.body}</p>
                {selectedAdDetail.adObject.adLink && (
                  <a
                    href={selectedAdDetail.adObject.adLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={S.adLink}
                  >
                    광고 링크로 이동
                  </a>
                )}
                {selectedAdDetail.adObject.imageUrl ? (
                  <img
                    src={selectedAdDetail.adObject.imageUrl}
                    alt={selectedAdDetail.adObject.title}
                    className={S.adImage}
                  />
                ) : (
                  <div>이미지를 불러올 수 없습니다.</div>
                )}
              </>
            )}
          </div>

          {/* 오른쪽: 광고 성과 테이블 */}
          <div className={S.insightSection}>
            <h3>Insights</h3>
            <div className={S.insightTableWrapper}>
              <table className={S.insightTable}>
                <thead>
                  <tr>
                    {fields.map((field) => (
                      <th key={field}>{field}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {matchingAdInsights.map((adInsight, index) => (
                    <tr key={index}>
                      <td>{adInsight.date}</td>
                      <td>{selectedAdDetail.name}</td>
                      <td>{adInsight.spend?.toLocaleString()}</td>
                      <td>{adInsight.impressions?.toLocaleString()}</td>
                      <td>{adInsight.ctr?.toLocaleString()}</td>
                      <td>{adInsight.cpc?.toLocaleString()}</td>
                      <td>{adInsight.cpm?.toLocaleString()}</td>
                      <td>{adInsight.roas?.toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    )
  }

  const getAdImage = (ad: TikTokAdType) => {
    return ad.adObject?.imageUrl || '';
  };

  // 상단 오른쪽 광고 리스트 처리
  const renderAdsList = () => (
    <div className={S.adsListWrapper}>
      <table className={S.dataTable}>
        <thead>
          <tr>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {adGroupDetail.ads.map((ad) => (
            <tr 
              key={ad.adId} 
              onClick={() => handleAdClick(ad)}
              className={selectedAd?.adId === ad.adId ? S.selectedRow : ''}
            >
              <td>{ad.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  const renderAdInsights = (ad: any) => {
    if (!selectedAd || selectedAd.adId !== ad.adId || !selectedAd.insights?.length) return null;
    
    return (
      <tr className={S.insightRow}>
        <td colSpan={4}>
          <div className={S.adDetailWrapper}>
            <div className={S.actionButtons}>
              <button 
                className={S.viewDetailButton}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowCreativeDetail(ad.adId);
                }}
              >
                <IoEyeOutline />
                광고 소재 자세히 보기
              </button>
            </div>

            <table className={S.insightTable}>
              <thead>
                <tr>
                  <th>Date</th>
                  {fields.map((field) => (
                    <th key={field}>{field}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {selectedAd?.insights?.map((insight: TikTokAdInsightType) => (
                  <tr key={insight.date}>
                    <td>{insight.date}</td>
                    {fields.map((field: string) => (
                      <td key={field}>
                        {insight[field as keyof TikTokAdInsightType] !== null
                          ? insight[field as keyof TikTokAdInsightType]
                          : '-'}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    );
  };

  const renderCreativeDetailModal = () => {
    if (!showCreativeDetail) return null;

    const ad = adGroupDetail?.ads.find(ad => ad.adId === showCreativeDetail);
    if (!ad) return null;

    const adCreative: AdCreativeType = {
      title: (ad.adObject as any)?.title,
      description: (ad.adObject as any)?.description,
      imageUrl: (ad.adObject as any)?.imageUrl,
      adLink: (ad.adObject as any)?.adLink
    };

    return (
      <div
        className={S.modalOverlay}
        onClick={() => setShowCreativeDetail(null)}
      >
        <div className={S.modalContent} onClick={(e) => e.stopPropagation()}>
          <div className={S.modalHeader}>
            <h3>광고 소재 상세</h3>
            <button
              className={S.modalCloseButton}
              onClick={() => setShowCreativeDetail(null)}
            >
              ✕
            </button>
          </div>
          <div className={S.modalBody}>
            <div className={S.adDetailSection}>
              <div className={S.materialSection}>
                <h4>광고 소재</h4>
                {adCreative.title && (
                  <div className={S.adBody}>
                    <p>{adCreative.title || '광고 문구가 없습니다.'}</p>
                  </div>
                )}
                {adCreative.adLink && (
                  <a
                    href={adCreative.adLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={S.adLink}
                  >
                    {adCreative.adLink}
                  </a>
                )}
                {adCreative.imageUrl && (
                  <img
                    src={adCreative.imageUrl}
                    alt={adCreative.title}
                    className={S.adImage}
                  />
                )}
              </div>
              <div className={S.insightSection}>
                <h3>광고 정보</h3>
                <table className={S.insightTable}>
                  <tbody>
                    <tr>
                      <th>광고 ID</th>
                      <td>{ad.adId}</td>
                    </tr>
                    <tr>
                      <th>광고명</th>
                      <td>{ad.name}</td>
                    </tr>
                    <tr>
                      <th>상태</th>
                      <td>{ad.status}</td>
                    </tr>
                    <tr>
                      <th>생성일</th>
                      <td>
                        {ad.createdTime
                          ? dayjs(ad.createdTime).format('YYYY.MM.DD HH:mm')
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <th>수정일</th>
                      <td>
                        {ad.updatedTime
                          ? dayjs(ad.updatedTime).format('YYYY.MM.DD HH:mm')
                          : '-'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  };

  return (
    <div className={S.popupOverlay}>
      <div className={S.popupContent}>
        <div className={S.popupHeader}>
          <h2>Details</h2>
          <button className={S.closeButton} onClick={() => onClose('AD-GROUP')}>✕</button>
        </div>

        <div className={S.contentWrapper}>
          {/* AdGroup Info Section */}
          <div className={S.section}>
            <h3>광고그룹 정보</h3>
            <table className={S.dataTable}>
              <tbody>
                <tr>
                  <th>광고그룹 ID</th>
                  <td>{adGroupDetail?.adGroupId}</td>
                  <th>상태</th>
                  <td>{adGroupDetail?.status}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* History Section */}
          {adGroupHistories.length > 0 && (
            <div className={S.section}>
              <div 
                className={S.sectionHeader}
                onClick={() => setShowHistory(!showHistory)}
              >
                <h3>광고그룹 제어 히스토리</h3>
                <button className={S.toggleButton}>
                  {showHistory ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
                </button>
              </div>
              {showHistory && (
                <table className={S.dataTable}>
                  <thead>
                    <tr>
                      <th>날짜</th>
                      <th>제어 유형</th>
                      <th>User</th>
                      <th>Memo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adGroupHistories.map((history) => (
                      <tr key={history.updatedAt}>
                        <td>{dayjs(history.updatedAt).format('YYYY.MM.DD HH:mm')}</td>
                        <td>{history.description}</td>
                        <td>{history.userEmail}</td>
                        <td>{history.memo || '-'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}

          {/* Ad List Section */}
          <div className={S.adListSection}>
            <div className={S.sectionHeader} onClick={() => setShowAdList(!showAdList)}>
              <h3>광고 목록</h3>
              <button className={S.toggleButton}>
                {showAdList ? <IoChevronUpOutline /> : <IoChevronDownOutline />}
              </button>
            </div>
            {showAdList && (
              <table className={S.dataTable}>
                <thead>
                  <tr>
                    <th>썸네일</th>
                    <th>광고 ID</th>
                    <th>광고명</th>
                    <th>상태</th>
                  </tr>
                </thead>
                <tbody>
                  {adGroupDetail?.ads.map((ad: TikTokAdType) => (
                    <React.Fragment key={ad.adId}>
                      <tr 
                        onClick={() => handleAdClick(ad)}
                        className={`${S.clickableRow} ${selectedAd?.adId === ad.adId ? S.selectedRow : ''}`}
                      >
                        <td className={S.thumbnailCell}>
                          {getAdImage(ad) ? (
                            <img 
                              src={getAdImage(ad)} 
                              alt={ad.name}
                              className={S.thumbnail}
                            />
                          ) : (
                            <div className={S.noThumbnail}>No Image</div>
                          )}
                        </td>
                        <td>{ad.adId}</td>
                        <td>{ad.name}</td>
                        <td>{ad.status}</td>
                      </tr>
                      {renderAdInsights(ad)}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      {renderCreativeDetailModal()}
    </div>
  )
}

export default AdGroupCreativePopup
