import React, { useEffect, useState } from 'react';
import { CampaignDetailResponse, fetchCampaignDetails, updateCampaignDailyBudget, updateAdGroupStatus, updateCampaignStatus, fetchAdGroupDailyInsights, AdGroupDailyInsight } from '../../../../../../repository/GoogleDataRepository';
import styles from './CampaignDetailPopup.module.scss';
import { IoMdArrowDropdown, IoMdArrowDropup, IoMdClose } from 'react-icons/io';
import Switch from './Switch';

interface CampaignDetailPopupProps {
  campaignId: string;
  token: string;
  onClose: () => void;
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('ko-KR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).replace(/\. /g, '-').replace('.', '');
};

const CampaignDetailPopup: React.FC<CampaignDetailPopupProps> = ({
  campaignId,
  token,
  onClose,
}) => {
  const [data, setData] = useState<CampaignDetailResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedSections, setExpandedSections] = useState({
    basicInfo: true,
    dailyInsights: true,
    adGroups: true,
    ads: true,
  });
  const [editingBudget, setEditingBudget] = useState<number | null>(null);
  const [expandedAdGroup, setExpandedAdGroup] = useState<string | null>(null);
  const [adGroupInsights, setAdGroupInsights] = useState<Record<string, AdGroupDailyInsight[]>>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCampaignDetails(token, campaignId);
        setData(response);
      } catch (error) {
        console.error('Error fetching campaign details:', error);
        alert('캠페인 상세 정보를 불러오는데 실패했습니다.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [campaignId, token]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscKey);

    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose]);

  const toggleSection = (section: keyof typeof expandedSections) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const getAdGroupName = (adGroupId: string) => {
    const adGroup = data?.adGroups.find(group => group.adGroupId === adGroupId);
    return adGroup?.name || '-';
  };

  const handleBudgetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!/^\d*$/.test(value)) return;
    const newBudget = value === '' ? 0 : parseInt(value);
    if (newBudget < 0) return;
    setEditingBudget(newBudget);
  };

  const handleBudgetEdit = (type: 'increase' | 'decrease') => {
    if (!data) return;
    const percent = type === 'increase' ? 1.2 : 0.8;
    const newBudget = Math.floor(data.campaign.budget * percent);
    setEditingBudget(newBudget);
  };

  const updateBudget = async () => {
    if (!data || editingBudget === null) return;
    
    const message = `캠페인 예산을 ${editingBudget.toLocaleString()}원으로 설정하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');
    
    if (memo === null) return;

    setIsLoading(true);
    try {
      await updateCampaignDailyBudget(token, {
        id: data.campaign.campaignId,
        dailyBudget: editingBudget,
        memo: memo
      });
      
      alert(`[Campaign] ${data.campaign.name} 예산변경 성공`);
      const updatedData = await fetchCampaignDetails(token, campaignId);
      setData(updatedData);
      setEditingBudget(null);
    } catch (error: any) {
      console.error('Error updating campaign budget:', error);
      alert(error.message || `[Campaign] ${data.campaign.name} 예산변경 실패\n관리자 문의 부탁드립니다.`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdGroupStatusChange = async (adGroupId: string, currentStatus: string, adGroupName: string) => {
    if (data?.campaign.advertisingChannelType === 'VIDEO') {
      return;
    }

    const newStatus = currentStatus === 'ENABLED' ? 'PAUSED' : 'ENABLED';
    const message = `광고그룹 상태를 ${newStatus === 'ENABLED' ? '활성화' : '중지'}하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');
    
    if (memo === null) return;

    setIsLoading(true);
    try {
      await updateAdGroupStatus(token, {
        id: adGroupId,
        status: newStatus,
        memo: memo
      });
      
      alert(`[AdGroup] ${adGroupName} 상태변경 성공`);
      const updatedData = await fetchCampaignDetails(token, campaignId);
      setData(updatedData);
    } catch (error: any) {
      console.error('Error updating ad group status:', error);
      alert(error.message || `[AdGroup] ${adGroupName} 상태변경 실패\n관리자 문의 부탁드립니다.`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCampaignStatusChange = async () => {
    if (!data) return;
    
    if (data.campaign.advertisingChannelType === 'VIDEO') {
      alert('VIDEO 캠페인은 상태를 변경할 수 없습니다.');
      return;
    }
    
    const newStatus = data.campaign.status === 'ENABLED' ? 'PAUSED' : 'ENABLED';
    const message = `캠페인 상태를 ${newStatus === 'ENABLED' ? '활성화' : '중지'}하시겠습니까?`;
    const memo = window.prompt(`${message}\n\nMemo`, '');
    
    if (memo === null) return;

    setIsLoading(true);
    try {
      await updateCampaignStatus(token, {
        id: data.campaign.campaignId,
        status: newStatus,
        memo: memo
      });
      
      alert(`[Campaign] ${data.campaign.name} 상태변경 성공`);
      const updatedData = await fetchCampaignDetails(token, campaignId);
      setData(updatedData);
    } catch (error: any) {
      console.error('Error updating campaign status:', error);
      alert(error.message || `[Campaign] ${data.campaign.name} 상태변경 실패\n관리자 문의 부탁드립니다.`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdGroupRowClick = async (adGroupId: string) => {
    if (expandedAdGroup === adGroupId) {
      setExpandedAdGroup(null);
      return;
    }

    setExpandedAdGroup(adGroupId);

    if (!adGroupInsights[adGroupId]) {
      try {
        const insights = await fetchAdGroupDailyInsights(token, adGroupId);
        setAdGroupInsights(prev => ({
          ...prev,
          [adGroupId]: insights
        }));
      } catch (error) {
        console.error('Error fetching ad group insights:', error);
        alert('광고그룹 인사이트를 불러오는데 실패했습니다.');
      }
    }
  };

  if (isLoading) {
    return (
      <div className={styles.popupOverlay}>
        <div className={styles.popupContent}>
          <div className={styles.loadingWrapper}>
            <div className={styles.loader} />
            <p>데이터를 불러오는 중입니다...</p>
          </div>
        </div>
      </div>
    );
  }

  if (!data) return null;

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupContent}>
        <div className={styles.popupHeader}>
          <h3>캠페인 상세 정보</h3>
          <button className={styles.closeButton} onClick={onClose}>
            <IoMdClose />
          </button>
        </div>
        
        <div className={styles.section}>
          <div className={styles.sectionHeader} onClick={() => toggleSection('basicInfo')}>
            <h4>기본 정보</h4>
            {expandedSections.basicInfo ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </div>
          {expandedSections.basicInfo && (
            <div className={styles.infoGrid}>
              <div className={styles.infoItem}>
                <span className={styles.label}>캠페인명</span>
                <span className={styles.value}>{data.campaign.name}</span>
              </div>
              <div className={styles.infoItem}>
                <span className={styles.label}>상태</span>
                <Switch
                  checked={data.campaign.status === 'ENABLED'}
                  onChange={handleCampaignStatusChange}
                  disabled={data.campaign.advertisingChannelType === 'VIDEO'}
                  disabledMessage="VIDEO 캠페인은 상태를 변경할 수 없습니다."
                />
              </div>
              <div className={styles.infoItem}>
                <span className={styles.label}>시작일</span>
                <span className={styles.value}>
                  {formatDate(data.campaign.startDate)}
                </span>
              </div>
              <div className={styles.infoItem}>
                <span className={styles.label}>종료일</span>
                <span className={styles.value}>
                  {formatDate(data.campaign.endDate)}
                </span>
              </div>
              <div className={styles.infoItem}>
                <span className={styles.label}>일일 예산</span>
                <div className={styles.budgetControls}>
                  <input
                    type="number"
                    value={editingBudget ?? data.campaign.budget}
                    onChange={handleBudgetChange}
                    className={styles.budgetInput}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <div className={styles.budgetButtons}>
                    <button
                      className={styles.budgetBtn}
                      onClick={(e) => {
                        e.stopPropagation();
                        updateBudget();
                      }}
                    >
                      예산 적용
                    </button>
                    <button
                      className={`${styles.percentButton} ${styles.increase}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleBudgetEdit('increase');
                      }}
                    >
                      <span className={styles.icon}>↑</span>
                      20%
                    </button>
                    <button
                      className={`${styles.percentButton} ${styles.decrease}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleBudgetEdit('decrease');
                      }}
                    >
                      <span className={styles.icon}>↓</span>
                      20%
                    </button>
                  </div>
                </div>
              </div>
              <div className={styles.infoItem}>
                <span className={styles.label}>광고 채널</span>
                <span className={styles.value}>{data.campaign.advertisingChannelType}</span>
              </div>
            </div>
          )}
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeader} onClick={() => toggleSection('dailyInsights')}>
            <h4>캠페인 일별 인사이트</h4>
            {expandedSections.dailyInsights ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </div>
          {expandedSections.dailyInsights && (
            <div className={styles.tableWrapper}>
              <table className={styles.insightTable}>
                <thead>
                  <tr>
                    <th>날짜</th>
                    <th>지출</th>
                    <th>클릭</th>
                    <th>노출</th>
                    <th>CTR</th>
                    <th>CPC</th>
                    <th>CPM</th>
                    {data.dailyInsights[0].roas !== null && (
                      <>
                        <th>ROAS</th>
                        <th>매출</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data.dailyInsights.map((insight) => (
                    <tr key={insight.fromDate}>
                      <td>{insight.fromDate}</td>
                      <td>{Math.floor(insight.spend).toLocaleString()}원</td>
                      <td>{insight.clicks.toLocaleString()}</td>
                      <td>{insight.impressions.toLocaleString()}</td>
                      <td>{Math.floor(insight.ctr)}%</td>
                      <td>{Math.floor(insight.cpc).toLocaleString()}원</td>
                      <td>{Math.floor(insight.cpm).toLocaleString()}원</td>
                      {insight.roas !== null && (
                        <>
                          <td>{Math.floor(insight.roas)}%</td>
                          <td>{insight.revenue?.toLocaleString()}원</td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeader} onClick={() => toggleSection('adGroups')}>
            <h4>광고 그룹 목록</h4>
            {expandedSections.adGroups ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </div>
          {expandedSections.adGroups && (
            <div className={styles.tableWrapper}>
              <table className={styles.insightTable}>
                <thead>
                  <tr>
                    <th>광고그룹명</th>
                    <th>상태</th>
                    <th>타입</th>
                    <th>CPC 입찰가</th>
                    <th>CPM 입찰가</th>
                    <th>메모</th>
                    <th>인사이트</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.adGroups.map((adGroup) => (
                    <React.Fragment key={adGroup.id}>
                      <tr>
                        <td>{adGroup.name}</td>
                        <td>
                          <Switch
                            checked={adGroup.status === 'ENABLED'}
                            onChange={() => {
                              handleAdGroupStatusChange(
                                adGroup.adGroupId,
                                adGroup.status,
                                adGroup.name
                              );
                            }}
                            disabled={data.campaign.advertisingChannelType === 'VIDEO'}
                            disabledMessage="VIDEO 캠페인의 광고그룹은 상태를 변경할 수 없습니다."
                          />
                        </td>
                        <td>{adGroup.type}</td>
                        <td>{adGroup.cpcBids?.toLocaleString()}원</td>
                        <td>{adGroup.cpmBids?.toLocaleString()}원</td>
                        <td>{adGroup.memo || '-'}</td>
                        <td>
                          <button
                            className={styles.insightButton}
                            onClick={() => handleAdGroupRowClick(adGroup.adGroupId)}
                          >
                            {expandedAdGroup === adGroup.adGroupId ? '접기' : '보기'}
                          </button>
                        </td>
                      </tr>
                      {expandedAdGroup === adGroup.adGroupId && adGroupInsights[adGroup.adGroupId] && (
                        <tr>
                          <td colSpan={7}>
                            <div className={styles.insightDetail}>
                              <table className={styles.insightTable}>
                                <thead>
                                  <tr>
                                    <th>날짜</th>
                                    <th>지출</th>
                                    <th>클릭</th>
                                    <th>노출</th>
                                    <th>CTR</th>
                                    <th>CPC</th>
                                    <th>CPM</th>
                                    {adGroupInsights[adGroup.adGroupId][0].roas !== null && (
                                      <>
                                        <th>ROAS</th>
                                        <th>매출</th>
                                      </>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {adGroupInsights[adGroup.adGroupId].map((insight) => (
                                    <tr key={insight.fromDate}>
                                      <td>{insight.fromDate}</td>
                                      <td>{Math.floor(insight.spend).toLocaleString()}원</td>
                                      <td>{insight.clicks.toLocaleString()}</td>
                                      <td>{insight.impressions.toLocaleString()}</td>
                                      <td>{Math.floor(insight.ctr)}%</td>
                                      <td>{Math.floor(insight.cpc).toLocaleString()}원</td>
                                      <td>{Math.floor(insight.cpm).toLocaleString()}원</td>
                                      {insight.roas !== null && (
                                        <>
                                          <td>{Math.floor(insight.roas)}%</td>
                                          <td>{insight.revenue?.toLocaleString()}원</td>
                                        </>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className={styles.section}>
          <div className={styles.sectionHeader} onClick={() => toggleSection('ads')}>
            <h4>광고 목록</h4>
            {expandedSections.ads ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </div>
          {expandedSections.ads && (
            <div className={styles.tableWrapper}>
              <table className={styles.insightTable}>
                <thead>
                  <tr>
                    <th>광고그룹</th>
                    <th>광고명</th>
                    <th>상태</th>
                    <th>타입</th>
                    <th>승인상태</th>
                    <th>최종 URL</th>
                  </tr>
                </thead>
                <tbody>
                  {data.ads.map((ad) => (
                    <tr key={ad.id}>
                      <td>{getAdGroupName(ad.adGroupId)}</td>
                      <td>{ad.name}</td>
                      <td>{ad.status}</td>
                      <td>{ad.type}</td>
                      <td>{ad.approvalStatus}</td>
                      <td>
                        <a 
                          href={ad.finalUrls} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className={styles.urlLink}
                        >
                          URL 바로가기
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CampaignDetailPopup; 