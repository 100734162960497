import React, { useState } from 'react';
import S from './EditUserTypeModal.module.scss';
import { UserTypeEnum } from '../../../../types/LoginTypes';

interface EditUserTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (type: string) => void;
  currentType: string;
  email: string;
}

const EditUserTypeModal = ({ isOpen, onClose, onConfirm, currentType, email }: EditUserTypeModalProps) => {
  const [selectedType, setSelectedType] = useState(currentType);

  if (!isOpen) return null;

  const userTypes = Object.entries(UserTypeEnum)
    .filter(([key]) => key !== 'ADMIN') // ADMIN 타입 제외
    .map(([key, value]) => ({
      value: key,
      label: value
    }));

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onConfirm(selectedType);
  };

  return (
    <div className={S.overlay}>
      <div className={S.modal}>
        <h2>사용자 권한 수정</h2>
        <p><strong>{email}</strong> 사용자의 권한을 수정합니다.</p>
        
        <form onSubmit={handleSubmit}>
          <div className={S.field}>
            <label>권한</label>
            <select 
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              className={S.select}
            >
              {userTypes.map(({ value, label }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>

          <div className={S.actions}>
            <button type="button" onClick={onClose} className={S.cancelButton}>
              취소
            </button>
            <button type="submit" className={S.confirmButton}>
              수정
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUserTypeModal; 