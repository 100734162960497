import React from 'react';
import styles from './Switch.module.scss';

interface SwitchProps {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
  disabledMessage?: string;
}

const Switch: React.FC<SwitchProps> = ({ 
  checked, 
  onChange, 
  disabled = false,
  disabledMessage = '이 작업은 수행할 수 없습니다.'
}) => {
  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault();
      alert(disabledMessage);
    }
  };

  return (
    <label className={`${styles.switch} ${disabled ? styles.disabled : ''}`} onClick={handleClick}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className={styles.slider}></span>
    </label>
  );
};

export default Switch; 