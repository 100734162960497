import React from 'react';
import S from './DeleteConfirmModal.module.scss';

interface DeleteConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  email: string;
}

const DeleteConfirmModal = ({ isOpen, onClose, onConfirm, email }: DeleteConfirmModalProps) => {
  if (!isOpen) return null;

  return (
    <div className={S.overlay}>
      <div className={S.modal}>
        <h2>사용자 삭제</h2>
        <p><strong>{email}</strong> 사용자를 삭제하시겠습니까?</p>
        <div className={S.actions}>
          <button onClick={onClose} className={S.cancelButton}>
            취소
          </button>
          <button onClick={onConfirm} className={S.deleteButton}>
            삭제
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmModal; 